/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Paper, Typography, Grid, TextField, TableHead, Table, TableRow, TableCell, TableBody, Button, Switch, FormControlLabel, Input, Link,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { DropzoneArea } from 'material-ui-dropzone';
import firebase from 'firebase/app';
import { RootState } from '../redux/root';
import {
  setAddFeedNutrient, SET_ADD_FEED_NUTRIENT, setAddFeedValue, SET_ADD_FEED_VALUE, resetAddFeedState, RESET_ADD_FEED_STATE, toggleAddFeedDM, TOGGLE_ADD_FEED_DM,
} from '../redux/addFeed';
import {
  Nutrients, Nutrient, Feed, User, addFeed, ADD_FEED,
} from '../redux/types';
import { displayBrandSelector, displayCategorySelector, getSelectors } from './Feeds';
import 'firebase/firestore';


export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexGrow: 1,
  },
  paper: {
    flexGrow: 1,
    padding: '1em',
    display: 'flex',
    width: '100vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const AddFeedToFirebase = (feed: Feed, isPerKgFeed: boolean, category: string, brand: string, user: User, dispatch: Function, setFeedAdded: Function) => {
  // check if entering as DM or per kg feed
  const fixedPlaces: number = 2;
  const newFeed: any = { ...feed };
  if (!isPerKgFeed) {
    // convert to per KG feed
    Object.keys(newFeed.nutrients).forEach((key: string) => {
      (newFeed.nutrients[key as keyof Nutrients] as Nutrient).value = Number(((newFeed.nutrients[key as keyof Nutrients] as Nutrient).value * (feed.dm / 100)).toFixed(fixedPlaces));
    });
  }
  if (!category || !brand) {
    // error
    return undefined;
  }
  newFeed.ownerId = user.id;
  newFeed.ownerName = user.name;
  newFeed.creationDate = firebase.firestore.FieldValue.serverTimestamp();
  newFeed.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
  delete newFeed.brandId;
  delete newFeed.categoryId;
  delete newFeed.id;
  const ref: firebase.firestore.DocumentReference = firebase.firestore().collection('feeds').doc(category).collection('groups')
    .doc(brand)
    .collection('feeds')
    .doc();
  delete newFeed.ref;
  ref.set(newFeed);
  newFeed.id = ref.id;
  newFeed.ref = ref;
  newFeed.creationDate = new Date();
  newFeed.lastUpdated = new Date();
  const action: addFeed = {
    type: ADD_FEED,
    payload: [newFeed.id, newFeed],
  };
  dispatch(action);
  setTimeout(() => setFeedAdded(true), 500);
};

const addFeedNutrientChangeHandler = (nutrientKey: keyof Nutrients, e: any, dispatch: Function) => {
  const start = e.target.selectionStart;
  let val = e.target.value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  let v = '0';
  if (match) v = match[1] + match[2];
  if (val.length > 0) {
    e.target.value = Number(v).toFixed(2);
    e.target.setSelectionRange(start, start);
  }
  let value: number = Number(v);
  if (value < 0 || value > 500000 || isNaN(value)) {
    value = 0;
  }
  const action: setAddFeedNutrient = {
    type: SET_ADD_FEED_NUTRIENT,
    payload: [nutrientKey, value],
  };
  dispatch(action);
};

const addFeedKeyValueChangeHandler = (key: keyof Feed, newValue: any, dispatch: Function) => {
  const action: setAddFeedValue = {
    type: SET_ADD_FEED_VALUE,
    payload: [key, newValue],
  };
  dispatch(action);
};

export interface AddFeedCoreProps {
  user: User
  isPerKgFeed: boolean
  setIsPerKgFeed: Function
  isEditing?: boolean
}

export const AddFeedCore : React.FC<AddFeedCoreProps> = (props: AddFeedCoreProps) => {
  const addFeed = useSelector((state: RootState) => state.addFeed);
  const dispatch = useDispatch();
  return (
    <>
      <Grid item>
        <TextField
          autoFocus
          margin="dense"
          id=""
          label={props.isEditing ? 'New Name' : 'Feed Name'}
          type="text"
          value={addFeed.name}
          onChange={(e) => { addFeedKeyValueChangeHandler('name', e.target.value, dispatch); }}
        />
      </Grid>
      <Grid item>
        <TextField
          margin="dense"
          id="ownerName"
          label="Owner Name"
          disabled
          type="text"
          value={props.user.name}
        />
      </Grid>
      <Grid item>
        <TextField
          disabled
          margin="dense"
          id="ownerId"
          label="Owner Id"
          type="text"
          value={props.user.id}
        />
      </Grid>
      <Grid item>
        <TextField
          margin="dense"
          id="dryMatterPercent"
          label="Dry Matter %"
          type="text"
          value={addFeed.dm}
          onChange={(e) => (isNaN(Number(e.target.value)) ? addFeedKeyValueChangeHandler('dm', 0, dispatch) : addFeedKeyValueChangeHandler('dm', Number(e.target.value), dispatch))}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={(
            <Switch
              checked={props.isPerKgFeed}
              onChange={(e) => props.setIsPerKgFeed(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          )}
          label={props.isPerKgFeed ? 'Adding as per KG feed' : 'Adding as per KG DM'}
        />
      </Grid>
    </>
  );
};

const resetState = (dispatch: Function) => {
  const action: resetAddFeedState = {
    type: RESET_ADD_FEED_STATE,
  };
  dispatch(action);
};

const displayNutrientInput = (nutrient: Nutrient, key: keyof Nutrients, dispatch: Function) => (
  <TableRow>
    <TableCell component="th" scope="row">{nutrient.displayName}</TableCell>
    <TableCell align="right">
      <TextField
        style={{ marginTop: '10px' }}
        id={key}
        type="text"
        value={nutrient.value.toFixed(2)}
        variant="outlined"
        onChange={(e) => addFeedNutrientChangeHandler(key, e, dispatch)}
      />
    </TableCell>
    <TableCell align="right">{nutrient.unit}</TableCell>
  </TableRow>
);

export interface AddFeedNutrientsProps {
  nutrients: [Nutrient, keyof Nutrients][]
}

export const DEFAULT_NUTRIENT_ORDER: (keyof Nutrients)[] = [
  'energy',
  'crudeProtein',
  'lysine',
  'fibre',
  'fat',
  'starch',
  'sugar',
  'calcium',
  'phosphorous',
  'sodium',
  'chloride',
  'potassium',
  'magnesium',
  'sulphur',
  'iron',
  'iodine',
  'cobalt',
  'copper',
  'manganese',
  'zinc',
  'selenium',
  'vitA',
  'vitD',
  'vitE',
  'vitK',
  'biotin',
  'vitC',
];

export const AddFeedNutrients : React.FC<AddFeedNutrientsProps> = (props: AddFeedNutrientsProps) => {
  const dispatch = useDispatch();
  useEffect(() => resetState(dispatch), []);
  return (
    <Paper style={{ marginLeft: '1em' }} elevation={2}>
      <Table style={{ margin: '0' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nutrient Name</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="right">Unit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.nutrients.map((n) => displayNutrientInput(n[0], n[1], dispatch))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const convertNutrientsToBalancedArrays = (nutrients: Nutrients) => {
  const res: [Nutrient, keyof Nutrients][] = [];
  const keys = Object.keys(nutrients);
  keys.forEach((key) => res.push([(nutrients[key as keyof Nutrients] as Nutrient), key as keyof Nutrients]));
  // Also orders correctly
  res.sort((a, b) => DEFAULT_NUTRIENT_ORDER.indexOf(a[1]) - DEFAULT_NUTRIENT_ORDER.indexOf(b[1]));
  const n = Math.ceil(res.length / 3);
  const a = res.slice(0, n);
  const b = res.slice(n, n * 2);
  const c = res.slice(n * 2, keys.length);
  return [a, b, c];
};

export const handleFeedCSV = (e: any, dispatch: Function) => {
  if (e === undefined || e.target.files === undefined || e.target.files[0].type !== 'application/vnd.ms-excel') return;
  const fileReader = new FileReader();
  fileReader.onloadend = () => {
    if (fileReader.result === null) return;
    const a: any = String(fileReader.result).split('\n');
    a[0] = a[0].split(',');
    a[1] = a[1].split(',');
    for (let i = 0; i < a[0].length; i += 1) {
      const dummyTarget = {
        target: {
          value: a[1][i],
          selectionStart: 0,
          setSelectionRange: () => {},
        },
      };
      addFeedNutrientChangeHandler(a[0][i].trim(), dummyTarget, dispatch);
    }
  };
  fileReader.readAsText(e.target.files[0] as Blob);
};


const AddFeed : React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => getSelectors(dispatch), []);
  const [selectorState, setSelectorState] = useState(['-1', '-1']);
  const [isPerKgFeed, setIsPerKgFeed] = useState(true);
  const [feedAdded, setFeedAdded] = useState(false);
  const classes = useStyles();
  const cAddFeed = useSelector((state: RootState) => state.addFeed);
  const feeds = useSelector((state: RootState) => state.feeds);
  const user = useSelector((state: RootState) => state.user);
  const perKgFedChangeHandler = (bool: boolean) => {
    setIsPerKgFeed(bool);
    const action: toggleAddFeedDM = {
      type: TOGGLE_ADD_FEED_DM,
      payload: bool,
    };
    dispatch(action);
  };
  const [col1, col2, col3] = convertNutrientsToBalancedArrays(cAddFeed.nutrients);
  if (feedAdded) return <Redirect to="/Feeds" />;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} direction="column" style={{ textAlign: 'center' }}>
          <Grid item>
            <Typography variant="h2">Add New Feed</Typography>
          </Grid>
          <Grid item>
            <AddFeedCore isEditing={false} isPerKgFeed={isPerKgFeed} setIsPerKgFeed={perKgFedChangeHandler} user={user} />
          </Grid>
          <Grid item>
            {displayCategorySelector(feeds, selectorState as [string, string], setSelectorState)}
          </Grid>
          <Grid item>
            {displayBrandSelector(feeds, selectorState as [string, string], setSelectorState)}
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" disabled={selectorState[1] === '-1'} onClick={() => AddFeedToFirebase(cAddFeed, isPerKgFeed, selectorState[0], selectorState[1], user, dispatch, setFeedAdded)}>Save Feed To Cloud</Button>
          </Grid>
          <Grid item container direction="column" spacing={3} style={{ marginBottom: '2em' }}>
            <Grid item>
              <hr style={{ marginTop: '1em' }} />
            </Grid>
            <Grid item>
              <Typography>
                Upload feed values from
                &nbsp;
                <Link target="_blank" href="https://docs.google.com/spreadsheets/d/1LO8veS9NrM0XbFr82OLW4bFE0-36QcIBVEr8ixof4dQ/edit?usp=sharing">official template.</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Input type="file" onChange={(e) => handleFeedCSV(e, dispatch)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col1} key="col1" />
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col2} key="col2" />
        </Grid>
        <Grid container direction="column">
          <AddFeedNutrients nutrients={col3} key="col3" />
        </Grid>
      </Paper>
    </div>
  );
};

export default AddFeed;
