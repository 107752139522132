import React from 'react';
import { Horse, HorseType } from '../../redux/types';
import { monthsToString } from '../../helpers/HorseAgeHelper';
import { extractHorseSpecialInformation } from '../HorseManageInformation';

interface Props {
  horse: Horse
}

const DietHorseInfo: React.FC<Props> = (props: Props) => (
  <div style={{ fontSize: '15px' }}>
    <div>
      <span style={{ fontWeight: 'bold' }}>Name:</span>
      &nbsp;
      {props.horse.name}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Age:</span>
      &nbsp;
      {monthsToString(props.horse.age)}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Breed:</span>
      &nbsp;
      {props.horse.breed}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Mature Adult Weight:</span>
      &nbsp;
      {props.horse.weight}
      &nbsp;
      kg
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Type:</span>
      &nbsp;
      {HorseType[props.horse.type]}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {extractHorseSpecialInformation(props.horse)[0]}
        :
      </span>
      &nbsp;
      {extractHorseSpecialInformation(props.horse)[1]}
    </div>
  </div>
);

export default DietHorseInfo;
