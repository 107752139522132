/* User Logins */
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';

export enum accountType {
    User = 0,
    Operator = 1,
    Admin = 2,
}

export interface User {
    id: string
    loggedIn: boolean
    name: string
    email: string
    phoneNumber: string
    role: string
    creationDate: Date
    accountType: accountType
}

export interface EditableAccountOptions {
  name: string
  email: string
  phoneNumber: string
  role: string
}

export interface UserLogin {
    type: typeof USER_LOGIN,
    payload: User,
}

export interface UserLogout {
    type: typeof USER_LOGOUT,
}

export interface UpdateUserInformation {
  type: typeof UPDATE_USER_INFORMATION
  payload: EditableAccountOptions
}

export type UserAction = UserLogin | UserLogout | UpdateUserInformation

export const SET_EDITABLE_ACCOUNT_OPTION = 'SET_EDITABLE_ACCOUNT_OPTION';
export const SET_EDITABLE_ACCOUNT_OPTIONS = 'SET_EDITABLE_ACCOUNT_OPTIONS';
export const RESET_EDITABLE_ACCOUNT_OPTIONS = 'RESET_EDITABLE_ACCOUNT_OPTIONS';

export interface SetEditableAccountOption {
  type: typeof SET_EDITABLE_ACCOUNT_OPTION
  payload: [keyof EditableAccountOptions, string]
}
export interface SetEditableAccountOptions {
  type: typeof SET_EDITABLE_ACCOUNT_OPTIONS
  payload: EditableAccountOptions
}

export interface ResetEditableAccountOptions {
  type: typeof RESET_EDITABLE_ACCOUNT_OPTIONS
}

export type EditableAccountOptionsActions = SetEditableAccountOption | SetEditableAccountOptions | ResetEditableAccountOptions

/* Clients */

export interface Clients {
    clients: Client[]
    filter: string
}

export interface Client {
    id: string,
    name: string
    creationDate: Date
    lastUpdated: Date
    horses: Horse[]
}

export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const SET_HORSES = 'SET_HORSES';
export const ADD_CLIENT_HORSE = 'ADD_CLIENT_HORSE';
export const REMOVE_CLIENT_HORSE = 'REMOVE_CLIENT_HORSE';
export const UPDATE_CLIENT_NAME = 'UPDATE_CLIENT_NAME';

export interface UpdateClients {
    type: typeof UPDATE_CLIENTS
    payload: Client[]
}

export interface AddClient {
    type: typeof ADD_CLIENT
    payload: Client
}

export interface RemoveClient {
  type: typeof REMOVE_CLIENT
  payload: string // clientId
}

export interface SetHorses {
    type: typeof SET_HORSES,
    payload: {
        clientId: string,
        horses: Horse[]
    }
}

export interface AddClientHorse {
    type: typeof ADD_CLIENT_HORSE,
    payload: {
        clientId: string,
        horse: Horse
    }
}

export interface RemoveClientHorse {
  type: typeof REMOVE_CLIENT_HORSE
  payload: {
    clientId: string,
    horseId: string,
  }
}

export interface UpdateClientName {
  type: typeof UPDATE_CLIENT_NAME
  payload: {
    id: string
    name: string
  }
}

export type ClientsActions = UpdateClients | AddClient | SetHorses
                             | AddClientHorse | RemoveClientHorse
                             | UpdateClientName | RemoveClient

/* Horses */

export enum HorseType {
    Maintenace = 0,
    Stallion = 1,
    Growing = 2,
    Pregnant = 3,
    Lactating = 4,
    Exercise = 5,
}

export interface HorseSpecial {
  maintenanceLevel?: number
  workLoad?: number
  growingWorkLoad?: number
  lactationMonth?: number
  gestationMonth?: number
  stallionMaintenanceLevel?: number
}

export interface Horse {
    id: string
    name: string
    breed: string
    weight: number
    age: number
    intakeLevel: number
    type: HorseType
    comment: string
    lastUpdated: Date
    creationDate: Date
    special?: HorseSpecial
}

export interface MaintenanceHorse extends Horse {
    special: {
      maintenanceLevel: number // low, avg, high
    }
}

export interface StallionHorse extends Horse {
  special: {
  stallionMaintenanceLevel: number // breeding or not breeding
  }
}

export interface GrowingHorse extends Horse {
  special: {
    growingWorkLoad: number // none, low, mod, heavy, vheavy
  }
}

export interface PregnantHorse extends Horse {
  special: {
    gestationMonth: number // month of gestation
  }
}

export interface LactatingHorse extends Horse {
  special: {
    lactationMonth: number // month of lactation
  }
}

export interface ExerciseHorse extends Horse {
  special: {
    workLoad: number // Low, mod, heavy, vheavy
  }
}

/* Add Horse */

export const ADD_HORSE_SET_NAME = 'ADD_HORSE_SET_NAME';
export const ADD_HORSE_SET_TYPE = 'ADD_HORSE_SET_TYPE';
export const ADD_HORSE_SET_WEIGHT = 'ADD_HORSE_SET_WEIGHT';
export const ADD_HORSE_SET_INTAKE_LEVEL = 'ADD_HORSE_SET_INTAKE_LEVEL';
export const ADD_HORSE_SET_BREED = 'ADD_HORSE_SET_BREED';
export const ADD_HORSE_SET_COMMENT = 'ADD_HORSE_SET_COMMENT';
export const ADD_HORSE_SET_AGE = 'ADD_HORSE_SET_AGE';
export const ADD_HORSE_SET_SPECIAL = 'ADD_HORSE_SET_SPECIAL';
export const ADD_HORSE_SET_ERROR = 'ADD_HORSE_SET_ERROR';
export const ADD_HORSE_PROPS_FROM_HORSE = 'ADD_HORSE_PROPS_FROM_HORSE';
export const ADD_HORSE_RESET_TO_DEFAULT = 'ADD_HORSE_RESET_TO_DEFAULT';

export interface AddHorseError {
    name?: string
    breed?: string
    weight?: string
    age?: string
    intakeLevel?: string
    type?: string
    comment?: string
    special?: string
  }

export interface AddHorseSetName {
    type: typeof ADD_HORSE_SET_NAME
    payload: string
}

export interface AddHorseSetType {
    type: typeof ADD_HORSE_SET_TYPE
    payload: HorseType
}

export interface AddHorseSetWeight {
    type: typeof ADD_HORSE_SET_WEIGHT
    payload: number // kg
}

export interface AddHorseSetIntakeLevel {
    type: typeof ADD_HORSE_SET_INTAKE_LEVEL
    payload: number
}

export interface AddHorseSetBreed {
    type: typeof ADD_HORSE_SET_BREED
    payload: string
}

export interface AddHorseSetComment {
    type: typeof ADD_HORSE_SET_COMMENT
    payload: string
}

export interface AddHorseSetAge {
    type: typeof ADD_HORSE_SET_AGE
    payload: number
}

export interface AddHorseSetSpecial {
    type: typeof ADD_HORSE_SET_SPECIAL
    payload: any
}

export interface AddHorseSetError {
    type: typeof ADD_HORSE_SET_ERROR
    payload: AddHorseError
}

export interface AddHorsePropsFromhorse {
  type: typeof ADD_HORSE_PROPS_FROM_HORSE
  payload: Horse
}

export interface AddHorseResetToDefault {
  type: typeof ADD_HORSE_RESET_TO_DEFAULT
}

export type AddHorseActions = AddHorseSetAge | AddHorseSetBreed | AddHorseSetError
    | AddHorseSetComment | AddHorseSetIntakeLevel | AddHorseSetName
    | AddHorseSetSpecial | AddHorseSetType | AddHorseSetWeight | AddHorsePropsFromhorse
    | AddHorseResetToDefault

/* Feeds */

export interface Nutrients {
    biotin: Nutrient
    calcium: Nutrient
    chloride: Nutrient
    cobalt: Nutrient
    copper: Nutrient
    crudeProtein: Nutrient
    energy: Nutrient
    fat: Nutrient
    fibre: Nutrient
    iodine: Nutrient
    iron: Nutrient
    lysine: Nutrient
    magnesium: Nutrient
    manganese: Nutrient
    phosphorous: Nutrient
    potassium: Nutrient
    selenium: Nutrient
    sodium: Nutrient
    starch: Nutrient
    sugar: Nutrient
    sulphur: Nutrient
    vitA: Nutrient
    vitC: Nutrient
    vitD: Nutrient
    vitE: Nutrient
    vitK: Nutrient
    zinc: Nutrient
}

export type Unit = 'MJ' | 'g' | 'mg' | 'kg' | 'IU' | '%'

export interface Nutrient {
    displayName: string,
    value: number,
    unit: Unit,
}

export interface Feed {
    id: string
    name: string
    nutrients: Nutrients
    dm: number
    creationDate: Date
    lastUpdated: Date
    ownerId: string
    ownerName: string
    brandId?: string,
    categoryId?: string,
    ref: firebase.firestore.DocumentReference
}

export interface Feeds {
    categories: Map<string, string>
    brands: [string, string, string][] // name, category
    feeds: Map<string, Feed>
}

export const SET_FEED_CATEGORIES = 'SET_FEED_CATEGORIES';
export const SET_FEED_BRANDS = 'SET_FEED_BRANDS';
export const ADD_FEED_BRAND = 'ADD_FEED_BRAND';
export const SET_FEEDS = 'SET_FEEDS';
export const ADD_FEED = 'ADD_FEED';
export const DELETE_FEED = 'DELETE_FEED';

export interface setFeedCategories {
    type: typeof SET_FEED_CATEGORIES
    payload: Map<string, string>
}

export interface setFeedBrands {
    type: typeof SET_FEED_BRANDS
    payload: [string, string, string][]
}

export interface setFeeds {
    type: typeof SET_FEEDS
    payload: Map<string, Feed>
}

export interface addFeed {
    type: typeof ADD_FEED
    payload: [string, Feed]
}

export interface deleteFeed {
  type: typeof DELETE_FEED
  payload: [string]
}

export interface addFeedBrand {
  type: typeof ADD_FEED_BRAND
  payload: [string, string, string]
}

export type FeedsAction = setFeedCategories | setFeedBrands
| setFeeds | addFeed | deleteFeed | addFeedBrand

export const defaultNutrients: Nutrients = {
  biotin: {
    displayName: 'Biotin',
    value: 0,
    unit: 'mg',
  },
  calcium: {
    displayName: 'Calcium',
    value: 0,
    unit: 'g',
  },
  chloride: {
    displayName: 'Chloride',
    value: 0,
    unit: 'g',
  },
  cobalt: {
    displayName: 'Cobalt',
    value: 0,
    unit: 'mg',
  },
  copper: {
    displayName: 'Copper',
    value: 0,
    unit: 'mg',
  },
  crudeProtein: {
    displayName: 'Crude Protein',
    value: 0,
    unit: 'g',
  },
  energy: {
    displayName: 'Energy',
    value: 0,
    unit: 'MJ',
  },
  fat: {
    displayName: 'Fat',
    value: 0,
    unit: 'g',
  },
  fibre: {
    displayName: 'Fibre',
    value: 0,
    unit: 'g',
  },
  iodine: {
    displayName: 'Iodine',
    value: 0,
    unit: 'mg',
  },
  iron: {
    displayName: 'Iron',
    value: 0,
    unit: 'mg',
  },
  lysine: {
    displayName: 'Lysine',
    value: 0,
    unit: 'g',
  },
  magnesium: {
    displayName: 'Magnesium',
    value: 0,
    unit: 'g',
  },
  manganese: {
    displayName: 'Manganese',
    value: 0,
    unit: 'mg',
  },
  phosphorous: {
    displayName: 'Phosphorus',
    value: 0,
    unit: 'g',
  },
  potassium: {
    displayName: 'Potassium',
    value: 0,
    unit: 'g',
  },
  selenium: {
    displayName: 'Selenium',
    value: 0,
    unit: 'mg',
  },
  sodium: {
    displayName: 'Sodium',
    value: 0,
    unit: 'g',
  },
  starch: {
    displayName: 'Starch',
    value: 0,
    unit: 'g',
  },
  sugar: {
    displayName: 'Sugar',
    value: 0,
    unit: 'g',
  },
  sulphur: {
    displayName: 'Sulphur',
    value: 0,
    unit: 'g',
  },
  vitA: {
    displayName: 'Vitamin A',
    value: 0,
    unit: 'IU',
  },
  vitC: {
    displayName: 'Vitamin C',
    value: 0,
    unit: 'mg',
  },
  vitD: {
    displayName: 'Vitamin D',
    value: 0,
    unit: 'IU',
  },
  vitE: {
    displayName: 'Vitamin E',
    value: 0,
    unit: 'mg',
  },
  vitK: {
    displayName: 'Vitamin K',
    value: 0,
    unit: 'mg',
  },
  zinc: {
    displayName: 'Zinc',
    value: 0,
    unit: 'mg',
  },
};

/* Reports */

export interface NutritionReport {
  horseId: string
  ownerId: string
  isPublic: boolean
  feedAmounts: Map<string, [firebase.firestore.DocumentReference, number]> // FeedId -> Amount in KG
  title: string
  note: string
  lastUpdated: Date
  creationDate: Date
  editableReportSections: Map<EditableReportSectionID, EditableReportSection>
  hasChanged?: boolean
}

export const SET_NUTRITION_REPORT = 'SET_NUTRITION_REPORT';
export const DELETE_NUTRITION_REPORT = 'DELETE_NUTRITION_REPORT';

export interface SetNutritionReport {
  type: typeof SET_NUTRITION_REPORT
  payload: [string, NutritionReport] // horseId, NutritionReport
}

export interface DeleteNutritionReport {
  type: typeof DELETE_NUTRITION_REPORT
  payload: string // horseId / nutritionReportId
}

export type NutritionReportAction = SetNutritionReport | DeleteNutritionReport

export const SET_CURRENT_NUTRITION_REPORT_VALUE = 'SET_CURRENT_NUTRITION_REPORT_VALUE';
export const SET_CURRENT_NUTRITION_REPORT = 'SET_CURRENT_NUTRITION_REPORT';
export const ADD_FEED_TO_CURRENT_NUTRITION_REPORT = 'ADD_FEED_TO_CURRENT_NUTRITION_REPORT';
export const REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT = 'REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT';
export const SET_CURRENT_NUTRITION_REPORT_AMOUNT = 'SET_CURRENT_NUTRITION_REPORT_AMOUNT';
export const RESET_CURRENT_NUTRITION_REPORT = 'RESET_CURRENT_NUTRITION_REPORT';
export const SET_EDITABLE_REPORT_SECTION = 'SET_EDITABLE_REPORT_SECTION';
export const RESET_EDITABLE_REPORT_SECTION = 'RESET_EDITABLE_REPORT_SECTION';

export interface SetCurrentNutritionReportValue {
  type: typeof SET_CURRENT_NUTRITION_REPORT_VALUE
  payload: [string, any] // string -> report value
}

export interface SetCurrentNutritionReport {
  type: typeof SET_CURRENT_NUTRITION_REPORT
  payload: NutritionReport
}

export interface AddFeedToCurrentNutritionReport {
  type: typeof ADD_FEED_TO_CURRENT_NUTRITION_REPORT
  payload: [string, firebase.firestore.DocumentReference], // feedId
}

export interface RemoveFeedFromCurrentNutritionReport {
  type: typeof REMOVE_FEED_FROM_CURRENT_NUTRITION_REPORT,
  payload: [string] // feedId
}

export interface SetCurrentNutritionReportFeedAmount {
  type: typeof SET_CURRENT_NUTRITION_REPORT_AMOUNT
  payload: [string, firebase.firestore.DocumentReference, number] // id, ref, feed amount in KG
}

export interface ResetCurrentNutritionReport {
  type: typeof RESET_CURRENT_NUTRITION_REPORT;
}

export interface SetEditableReportSection {
  type: typeof SET_EDITABLE_REPORT_SECTION,
  payload: EditableReportSection
}

export interface ResetEditableReportSection {
  type: typeof RESET_EDITABLE_REPORT_SECTION,
  payload: EditableReportSectionID
}

export type CurrentNutritionReportAction = SetCurrentNutritionReport |
                                            SetCurrentNutritionReportValue |
                                            AddFeedToCurrentNutritionReport |
                                            RemoveFeedFromCurrentNutritionReport |
                                            SetCurrentNutritionReportFeedAmount |
                                            ResetCurrentNutritionReport |
                                            SetEditableReportSection |
                                            ResetEditableReportSection

export interface SpecialNutritionalBounds {
  upper: {
    calcium: number
    potassium: number
    sodium: number
    chloride: number
    phosphorous: number
    magnesium: number
    copper: number
    manganese: number
    iron: number
    iodine: number
    selenium: number
    zinc: number
  }
}

/* Editable Report Sections */

export enum EditableReportSectionID {
  ReportTitle = 0,
  ReportDate = 1,
  ReportDescription = 2,
  ReportComments = 3,
}

export interface EditableReportSection {
  sectionID: EditableReportSectionID
  content: any
  isPlainText?: boolean
}
