/* eslint-disable no-tabs */
/* eslint-disable no-var */
/* eslint-disable camelcase */
// START OF CALCULATION
function calculateNutrientRequirements(AnimalType, MatureWeight, Age, IntakeLevel, MonthOfGest, MonthOfLact, MaintLevel, StallionMaintLevel, WorkLoadGrow, WorkLoad) {
  let BodyWeight; let DM_req; let DE_req; let CP_req; let LYS_req; let Ca_req; let P_req; let Mg_req; let K_req; let VitA_req; let Na_req; let Cl_req; let S_req; let Co_req; let Cu_req; let I_req; let Fe_req; let
    Mn_req;
  let Zn_req; let Se_req; let VitD_req; let VitE_req; let Thi_req; let Ribo_req; let
    SweatLoss;
  let FetalMass; let PUMass; let GestDay; let BirthWeight; let DailyGain; let
    FetalGain;
  switch (AnimalType) {
  case 'MAINTENANCE':
    // Adult Horse at maintenance
    BodyWeight = MatureWeight;
    DM_req = (IntakeLevel / 100) * BodyWeight;
    if (MaintLevel == 1) {
      // Minimum requirement
      DE_req = 0.0303 * BodyWeight;
      CP_req = 1.08 * BodyWeight;
    }
    if (MaintLevel == 2) {
      // Average requirement
      DE_req = 0.0333 * BodyWeight;
      CP_req = 1.26 * BodyWeight;
    }
    if (MaintLevel == 3) {
      // Elevated requirement
      DE_req = 0.0363 * BodyWeight;
      CP_req = 1.44 * BodyWeight;
    }
    LYS_req = 0.043 * CP_req;
    Ca_req = 0.04 * BodyWeight;
    P_req = 0.028 * BodyWeight;
    Mg_req = 0.015 * BodyWeight;
    K_req = 0.05 * BodyWeight;
    Na_req = 0.02 * BodyWeight;
    Cl_req = 0.08 * BodyWeight;
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    Cu_req = 0.2 * BodyWeight;
    I_req = 0.35 * DM_req;
    Fe_req = 40 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitA_req = 30 * BodyWeight;
    VitD_req = 6.6 * BodyWeight;
    VitE_req = 1.0 * BodyWeight;
    Thi_req = 0.06 * BodyWeight;
    Ribo_req = 0.04 * BodyWeight;
    break;

  case 'EXERCISE':
    BodyWeight = MatureWeight;
    DM_req = (IntakeLevel / 100) * BodyWeight;
    if (WorkLoad == 1) {
      // Light
      SweatLoss = 0.0025 * BodyWeight;
      DE_req = (0.0333 * BodyWeight) * 1.2;
      CP_req = (1.26 * BodyWeight) + (0.089 * BodyWeight) + (SweatLoss * 7.8 * 2.0 / 0.79);
      Ca_req = (0.06 * BodyWeight);
      P_req = (0.036 * BodyWeight);
      Mg_req = (0.019 * BodyWeight);
      VitE_req = 1.6 * BodyWeight;
      Thi_req = 0.06 * BodyWeight;
      Ribo_req = 0.04 * BodyWeight;
    }
    if (WorkLoad == 2) {
      // Moderate
      SweatLoss = 0.005 * BodyWeight;
      DE_req = (0.0333 * BodyWeight) * 1.4;
      CP_req = (1.26 * BodyWeight) + (0.177 * BodyWeight) + (SweatLoss * 7.8 * 2.0 / 0.79);
      Ca_req = (0.07 * BodyWeight);
      P_req = (0.042 * BodyWeight);
      Mg_req = (0.023 * BodyWeight);
      VitE_req = 1.8 * BodyWeight;
      Thi_req = 0.113 * BodyWeight;
      Ribo_req = 0.045 * BodyWeight;
    }
    if (WorkLoad == 3) {
      // Heavy
      SweatLoss = 0.01 * BodyWeight;
      DE_req = (0.0333 * BodyWeight) * 1.6;
      CP_req = (1.26 * BodyWeight) + (0.266 * BodyWeight) + (SweatLoss * 7.8 * 2.0 / 0.79);
      Ca_req = (0.08 * BodyWeight);
      P_req = (0.058 * BodyWeight);
      Mg_req = (0.03 * BodyWeight);
      VitE_req = 2.0 * BodyWeight;
      Thi_req = 0.125 * BodyWeight;
      Ribo_req = 0.05 * BodyWeight;
    }
    if (WorkLoad == 4) {
      // Very Heavy
      SweatLoss = 0.02 * BodyWeight;
      DE_req = (0.0363 * BodyWeight) * 1.9;
      CP_req = (1.26 * BodyWeight) + (0.354 * BodyWeight) + (SweatLoss * 7.8 * 2.0 / 0.79);
      Ca_req = (0.08 * BodyWeight);
      P_req = (0.058 * BodyWeight);
      Mg_req = (0.03 * BodyWeight);
      VitE_req = 2.0 * BodyWeight;
      Thi_req = 0.125 * BodyWeight;
      Ribo_req = 0.05 * BodyWeight;
    }
    LYS_req = 0.043 * CP_req;
    K_req = 0.05 * BodyWeight + 2.8 * SweatLoss;
    Na_req = 0.02 * BodyWeight + 3.1 * SweatLoss;
    Cl_req = 0.08 * BodyWeight + 5.3 * SweatLoss;
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    Cu_req = 10.0 * DM_req;
    I_req = 0.35 * DM_req;
    Fe_req = 40 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitA_req = 45 * BodyWeight;
    VitD_req = 6.6 * BodyWeight;
    break;

  case 'STALLION':
    // Stallion
    BodyWeight = MatureWeight;
    DM_req = (IntakeLevel / 100) * BodyWeight;
    if (StallionMaintLevel == 0)
    // Not Breeding
    {
      DE_req = 0.0363 * BodyWeight;
      CP_req = 1.44 * BodyWeight;
      Ca_req = 0.04 * BodyWeight;
      P_req = 0.028 * BodyWeight;
      Mg_req = 0.015 * BodyWeight;
      K_req = 0.05 * BodyWeight;
      Na_req = 0.02 * BodyWeight;
      Cl_req = 0.08 * BodyWeight;
      Cu_req = 0.2 * BodyWeight;
      VitA_req = 30 * BodyWeight;
      VitE_req = 1.0 * BodyWeight;
    }
    if (StallionMaintLevel == 1)
    // Breeding
    {
      SweatLoss = 0.0025 * BodyWeight; // - (Assumes light workload)
      DE_req = 0.0363 * BodyWeight * 1.2;
      CP_req = 1.44 * BodyWeight + (SweatLoss * 7.8 * 2 / 0.79) + 0.089 * BodyWeight;
      Ca_req = 0.06 * BodyWeight;
      P_req = 0.036 * BodyWeight;
      Mg_req = 0.019 * BodyWeight;
      K_req = 0.05 * BodyWeight + (1.4 / 0.5) * SweatLoss;
      Na_req = 0.02 * BodyWeight + 3.1 * SweatLoss;
      Cl_req = 0.08 * BodyWeight + 5.3 * SweatLoss;
      Cu_req = 10.0 * DM_req;
      VitA_req = 45 * BodyWeight;
      VitE_req = 1.6 * BodyWeight;
    }
    LYS_req = 0.043 * CP_req;
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    I_req = 0.35 * DM_req;
    Fe_req = 40 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitD_req = 6.6 * BodyWeight;
    Thi_req = 0.06 * BodyWeight;
    Ribo_req = 0.04 * BodyWeight;
    break;

  case 'PREGNANT':
    // Pregnancy
    DM_req = (IntakeLevel / 100) * MatureWeight;
    BodyWeight = MatureWeight;
    if (MonthOfGest < 5) {
      FetalMass = 0.0;
      PUMass = 0.0;
      FetalGain =	0.0;
      DE_req = 0.0333 * BodyWeight;
      CP_req = 1.26 * BodyWeight;
      Ca_req = 0.04 * BodyWeight;
      P_req = 0.028 * BodyWeight;
      Mg_req = 0.015 * BodyWeight;
      K_req = 0.05 * BodyWeight;
      Na_req = 0.02 * BodyWeight;
      Cl_req = 0.08 * BodyWeight;
      Cu_req = 0.2 * BodyWeight;
      I_req = 0.35 * DM_req;
      Fe_req = 40 * DM_req;
    } else {
      GestDay = MonthOfGest * 30.4;
      BirthWeight = 0.097 * MatureWeight;
      FetalMass = (0.0000001 * Math.pow(GestDay, 3.5512)) * 0.01 * BirthWeight;
      PUMass = (-0.0135 + (0.00009 * GestDay)) * BodyWeight;
      FetalGain =	(0.00000035512 * Math.pow(GestDay, 2.5512)) * 0.01 * BirthWeight + 0.00009 * BodyWeight;
      DE_req = (0.0333 * BodyWeight) + (0.0333 * 2 * (FetalMass + PUMass))
              + ((0.03 * FetalGain * 9.4) + (0.2 * FetalGain * 5.6)) / 0.6;
      CP_req = (1.26 * BodyWeight) + (FetalGain * 1000 * 2.0 * 0.2 / 0.79);
      if (MonthOfGest < 7) {
        Ca_req = 0.04 * BodyWeight;
        P_req = 0.028 * BodyWeight;
        Mg_req = 0.015 * BodyWeight;
        K_req = 0.05 * BodyWeight;
        Na_req = 0.02 * BodyWeight;
        Cl_req = 0.08 * BodyWeight;
        Cu_req = 0.2 * BodyWeight;
        I_req = 0.35 * DM_req;
        Fe_req = 40 * DM_req;
      } else if (MonthOfGest < 9) {
        Ca_req = 0.056 * BodyWeight;
        P_req = 0.04 * BodyWeight;
        Mg_req = 0.0152 * BodyWeight;
        K_req = 0.05 * BodyWeight;
        Na_req = 0.02 * BodyWeight;
        Cl_req = 0.08 * BodyWeight;
        Cu_req = 0.2 * BodyWeight;
        I_req = 0.35 * DM_req;
        Fe_req = 40 * DM_req;
      } else {
        Ca_req = 0.072 * BodyWeight;
        P_req = 0.0525 * BodyWeight;
        Mg_req = 0.0153 * BodyWeight;
        K_req = 0.0517 * BodyWeight;
        Na_req = 0.022 * BodyWeight;
        Cl_req = 0.082 * BodyWeight;
        Cu_req = 0.25 * BodyWeight;
        I_req = 0.4 * DM_req;
        Fe_req = 50 * DM_req;
      }
    }
    LYS_req = 0.043 * CP_req;
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitA_req = 60 * BodyWeight;
    VitD_req = 6.6 * BodyWeight;
    VitE_req = 1.0 * BodyWeight;
    Thi_req = 0.06 * BodyWeight;
    Ribo_req = 0.04 * BodyWeight;
    DailyGain = FetalGain * 1.25;
    BodyWeight += (FetalMass + PUMass) * 1.25;
    break;

  case 'LACTATING': // Lactating
    BodyWeight = MatureWeight;
    DM_req = (IntakeLevel / 100) * BodyWeight;

    var MilkVals;
    var MilkProd;
    MilkVals = new Array(6);
    MilkVals[0] = 0.0326;
    MilkVals[1] = 0.0324;
    MilkVals[2] = 0.0299;
    MilkVals[3] = 0.0271;
    MilkVals[4] = 0.0244;
    MilkVals[5] = 0.0218;

    MilkProd = MilkVals[MonthOfLact - 1] * BodyWeight;

    if (BodyWeight > 700) {
      DE_req = (0.0333 * BodyWeight) + ((MilkProd * 10 * 50) / (1000 * 0.6));
    } else {
      DE_req = (0.0363 * BodyWeight) + ((MilkProd * 10 * 50) / (1000 * 0.6));
    }

    CP_req = (1.44 * BodyWeight) + (MilkProd * 50);
    LYS_req = (0.043 * 1.44 * BodyWeight) + (MilkProd * 3.3);
    if (MonthOfLact < 4) 				// Lactation - 'early lact'
    {
      Na_req = 0.02 * BodyWeight + (MilkProd * 0.17);
      Ca_req = (0.04 * BodyWeight) + ((MilkProd * 1.2) / 0.5);
      P_req = (0.01 / 0.45 * BodyWeight) + ((MilkProd * 0.75) / 0.45);
      Mg_req = (0.015 * BodyWeight) + ((MilkProd * 0.09) / 0.4);
      K_req = (0.05 * BodyWeight) + ((MilkProd * 0.7) / 0.5);
    } else if (MonthOfLact < 6) {
      Na_req = 0.02 * BodyWeight + (MilkProd * 0.14);
      Ca_req = (0.04 * BodyWeight) + ((MilkProd * 0.8) / 0.5);
      P_req = (0.01 / 0.45 * BodyWeight) + ((MilkProd * 0.5) / 0.45);
      Mg_req = (0.015 * BodyWeight) + ((MilkProd * 0.09) / 0.4);
      K_req = (0.05 * BodyWeight) + ((MilkProd * 0.4) / 0.5);
    } else {
      Na_req = 0.02 * BodyWeight + (MilkProd * 0.14);
      Ca_req = (0.04 * BodyWeight) + ((MilkProd * 0.8) / 0.5);
      P_req = (0.01 / 0.45 * BodyWeight) + ((MilkProd * 0.5) / 0.45);
      Mg_req = (0.015 * BodyWeight) + ((MilkProd * 0.045) / 0.4);
      K_req = (0.05 * BodyWeight) + ((MilkProd * 0.4) / 0.5);
    }
    Cl_req = 0.091 * BodyWeight;
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    Cu_req = 0.25 * BodyWeight;
    I_req = 0.35 * DM_req;
    Fe_req = 50 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitA_req = 60 * BodyWeight;
    VitD_req = 6.6 * BodyWeight;
    VitE_req = 2.0 * BodyWeight;
    Thi_req = 0.075 * BodyWeight;
    Ribo_req = 0.05 * BodyWeight;
    break;

  case 'GROWING':					// Growing Animal
    BodyWeight = MatureWeight * (9.7 + (90.3 * (1.0 - (Math.exp(-0.0772 * Age))))) / 100.0;
    DailyGain = MatureWeight * (6.97121 * Math.exp(-0.0772 * Age)) / (30.4 * 100);
    DM_req = (IntakeLevel / 100) * BodyWeight;
    if (WorkLoadGrow == 0) {
      DE_req = ((56.5 * Math.pow(Age, -0.145)) / 1000) * BodyWeight + (1.99 + 1.21 * Age - 0.021 * Age * Age) * DailyGain;
      if (Age < 6.5) {
        CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.5) / 0.79;
        Cl_req = 0.093 * BodyWeight;
        VitD_req = 22.2 * BodyWeight;
      } else if (Age < 8.5) {
        CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.45) / 0.79;
        Cl_req = 0.085 * BodyWeight;
        VitD_req = 17.4 * BodyWeight;
      } else if (Age < 10.5) {
        CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.40) / 0.79;
        Cl_req = 0.085 * BodyWeight;
        VitD_req = 17.4 * BodyWeight;
      } else if (Age < 11.5) {
        CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.35) / 0.79;
        Cl_req = 0.085 * BodyWeight;
        VitD_req = 17.4 * BodyWeight;
      } else {
        CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.3) / 0.79;
        Cl_req = 0.0825 * BodyWeight;
        if (Age < 18.5) {
          VitD_req = 15.9 * BodyWeight;
        } else {
          VitD_req = 13.7 * BodyWeight;
        }
      }
      Mg_req = (0.015 * BodyWeight) + (1.25 * DailyGain);
      K_req = (0.05 * BodyWeight) + (3.0 * DailyGain);
      Na_req = 0.02 * BodyWeight + (1.0 * DailyGain);
    }
    if (WorkLoadGrow == 1) {
      // Only applied to animals > 12 mo of age
      SweatLoss = 0.0025 * BodyWeight;
      DE_req = ((56.5 * Math.pow(Age, -0.145)) / 1000) * 1.2 * BodyWeight + (1.99 + 1.21 * Age - 0.021 * Age * Age) * DailyGain;
      CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.3) / 0.79 + (SweatLoss * 7.8 * 2 / 0.79) + 0.089 * BodyWeight;
      Mg_req = (0.03 * BodyWeight);
      K_req = (0.05 * BodyWeight) + (3.0 * DailyGain) + (1.4 / 0.5) * SweatLoss;
      Na_req = 0.02 * BodyWeight + (1.0 * DailyGain) + 3.1 * SweatLoss;
      Cl_req = 0.0825 * BodyWeight + 5.3 * SweatLoss;
      if (Age < 18.5) {
        VitD_req = 15.9 * BodyWeight;
      } else {
        VitD_req = 13.7 * BodyWeight;
      }
    }
    if (WorkLoadGrow == 2) {
      // Only applied to animals > 12 mo of age
      SweatLoss = 0.005 * BodyWeight;
      DE_req = ((56.5 * Math.pow(Age, -0.145)) / 1000) * 1.4 * BodyWeight + (1.99 + 1.21 * Age - 0.021 * Age * Age) * DailyGain;
      CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.3) / 0.79 + (SweatLoss * 7.8 * 2 / 0.79) + 0.177 * BodyWeight;
      Mg_req = (0.03 * BodyWeight);
      K_req = (0.05 * BodyWeight) + (3.0 * DailyGain) + (1.4 / 0.5) * SweatLoss;
      Na_req = 0.02 * BodyWeight + (1.0 * DailyGain) + 3.1 * SweatLoss;
      Cl_req = 0.0825 * BodyWeight + 5.3 * SweatLoss;
      if (Age < 18.5) {
        VitD_req = 15.9 * BodyWeight;
      } else {
        VitD_req = 13.7 * BodyWeight;
      }
    }
    if (WorkLoadGrow == 3) {
      // Only applied to animals > 12 mo of age
      SweatLoss = 0.01 * BodyWeight;
      DE_req = ((56.5 * Math.pow(Age, -0.145)) / 1000) * 1.6 * BodyWeight + (1.99 + 1.21 * Age - 0.021 * Age * Age) * DailyGain;
      CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.3) / 0.79 + (SweatLoss * 7.8 * 2 / 0.79) + 0.266 * BodyWeight;
      Mg_req = (0.03 * BodyWeight);
      K_req = (0.05 * BodyWeight) + (3.0 * DailyGain) + (1.4 / 0.5) * SweatLoss;
      Na_req = 0.02 * BodyWeight + (1.0 * DailyGain) + 3.1 * SweatLoss;
      Cl_req = 0.0825 * BodyWeight + 5.3 * SweatLoss;
      if (Age < 18.5) {
        VitD_req = 15.9 * BodyWeight;
      } else {
        VitD_req = 13.7 * BodyWeight;
      }
    }
    if (WorkLoadGrow == 4) {
      // Only applied to animals > 12 mo of age
      SweatLoss = 0.02 * BodyWeight;
      DE_req = ((56.5 * Math.pow(Age, -0.145)) / 1000) * 1.9 * BodyWeight + (1.99 + 1.21 * Age - 0.021 * Age * Age) * DailyGain;
      CP_req = 1.44 * BodyWeight + ((DailyGain * 1000 * 0.2) / 0.3) / 0.79 + (SweatLoss * 7.8 * 2 / 0.79) + 0.354 * BodyWeight;
      Mg_req = (0.03 * BodyWeight);
      K_req = (0.05 * BodyWeight) + (3.0 * DailyGain) + (1.4 / 0.5) * SweatLoss;
      Na_req = 0.02 * BodyWeight + (1.0 * DailyGain) + 3.1 * SweatLoss;
      Cl_req = 0.0825 * BodyWeight + 5.3 * SweatLoss;
      if (Age < 18.5) {
        VitD_req = 15.9 * BodyWeight;
      } else {
        VitD_req = 13.7 * BodyWeight;
      }
    }


    LYS_req = 0.043 * CP_req;
    Ca_req = (0.072 * BodyWeight) + (32 * DailyGain);
    P_req = (0.04 * BodyWeight) + (17.8 * DailyGain);
    S_req = 1.5 * DM_req;
    Co_req = 0.05 * DM_req;
    Cu_req = 0.25 * BodyWeight;
    I_req = 0.35 * DM_req;
    Fe_req = 50 * DM_req;
    Mn_req = 40 * DM_req;
    Zn_req = 40 * DM_req;
    Se_req = 0.1 * DM_req;
    VitA_req = 45 * BodyWeight;
    VitD_req = 20 * BodyWeight;
    VitE_req = 2.0 * BodyWeight;
    Thi_req = 0.075 * BodyWeight;
    Ribo_req = 0.05 * BodyWeight;
    break;
  default:
    const error = 'No Animal Type';
  }
  const nutriReq = {

    Energy_req: DE_req * 4.184, // Conversion to MJ
    CrudeProtein_req: CP_req,
    Lysine_req: LYS_req,
    Calcium_req: Ca_req,
    Phosphorous_req: P_req,
    Magnesium_req: Mg_req,
    Sodium_req: Na_req,
    Potassium_req: K_req,
    Chloride_req: Cl_req,
    Iron_req: Fe_req,
    Copper_req: Cu_req,
    Manganese_req: Mn_req,
    Zinc_req: Zn_req,
    Selenium_req: Se_req,
    Iodine_req: I_req,
    VitaminA_req: VitA_req,
    VitaminD_req: VitD_req,
    VitaminE_req: VitE_req,
    Sulphur_req: S_req,
    Cobalt_req: Co_req,
    Thiamine_req: Thi_req,
    Riboflavin_req: Ribo_req,
  };
  return nutriReq;
}
export default calculateNutrientRequirements;
