/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material Ui Stuffs
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import firebase from 'firebase/app';
import { Link as RouterLink } from 'react-router-dom';
import {
  Horse, Client as IClient, SetHorses, SET_HORSES, User, AddHorseResetToDefault, ADD_HORSE_RESET_TO_DEFAULT,
} from '../redux/types';
import { RootState } from '../redux/root';
import AddHorseDialog from './AddHorseDialog';
import ManageClientDialog from './ManageClientDialog';
import getDateAsString from '../helpers/dateHelper';


const useStyles = makeStyles((theme) => ({
  '@global': {
    i: {
      fontSize: '2em',
    },
  },
  table: {
    margin: 'auto',
    maxWidth: '50em',
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    margin: 'auto',
    display: 'flex',
    alignContent: 'center',
    maxWidth: '50em',
  },
  fab: {
    margin: theme.spacing(2),
  },
  root: {
    margin: theme.spacing(2),
    marginTop: '3em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
}));

function fetchClientHorses(uid: string, clientId: string, dispatch: Function) {
  firebase.firestore()
    .collection('users')
    .doc(uid)
    .collection('clients')
    .doc(clientId)
    .collection('horses')
    .get()
    .then((resp) => {
      if (!resp.empty) {
        const horses: Horse[] = [];
        resp.docs.forEach((horseDoc) => {
          const horse: any = horseDoc.data();
          horse.lastUpdated = horse.lastUpdated.toDate();
          horse.creationDate = horse.creationDate.toDate();
          horse.id = horseDoc.id;
          horses.push(horse as Horse);
        });
        const action: SetHorses = {
          type: SET_HORSES,
          payload: {
            clientId,
            horses,
          },
        };
        dispatch(action);
      }
    });
}

function displayHorses(horses: Horse[], clientId: string) {
  const result: JSX.Element[] = [];
  horses.map((horse: Horse) => {
    result.push(
      <TableRow key={horse.id}>
        <TableCell>{horse.name}</TableCell>
        <TableCell>{getDateAsString(horse.lastUpdated)}</TableCell>
        <TableCell><IconButton size="small" color="secondary" component={RouterLink} to={`/Client/${clientId}/Horse/${horse.id}`}><KeyboardArrowRightRoundedIcon /></IconButton></TableCell>
      </TableRow>,
    );
    return undefined;
  });
  return result;
}

function displayFilter(filter: string, setFilter: Function) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="normal"
      id="filter"
      label="Filter"
      name="filter"
      autoComplete="filter"
      autoFocus
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}


interface Props {
  clientId: string
}

const handleAddHorse = (setAddHorseOpen: Function, dispatch: Function) => {
  const action: AddHorseResetToDefault = { type: ADD_HORSE_RESET_TO_DEFAULT };
  dispatch(action);
  setAddHorseOpen(true);
};

const Client : React.FC<Props> = (props: Props) => {
  const [filter, setFilter] = useState('');
  const [addHorseOpen, setAddHorseOpen] = useState(false);
  const [modifyClientOpen, setModifyClientOpen] = useState(false);
  const user: User = useSelector((state: RootState) => state.user);
  const dispatch: Function = useDispatch();
  useEffect(() => fetchClientHorses(user.id, props.clientId, dispatch), []);
  const currentClient: IClient = useSelector((state: RootState) => state.clients.clients
    .filter((client) => client.id === props.clientId))[0] as IClient;
  let { horses } = currentClient;
  const classes = useStyles();
  // eslint-disable-next-line max-len
  if (filter) horses = horses.filter((horse) => horse.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  horses.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1);
  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.grid}>
        {currentClient.name}
        &apos;s Horses
      </Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={8}>
          {displayFilter(filter, setFilter)}
        </Grid>
        <Grid item xs={2}>
          <Fab className={classes.fab} color="primary" aria-label="add horse" onClick={() => handleAddHorse(setAddHorseOpen, dispatch)}>
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs={2}>
          <Fab className={classes.fab} color="secondary" aria-label="modify client" onClick={() => setModifyClientOpen(true)}>
            <SettingsIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Horse Name</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayHorses(horses, currentClient.id)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AddHorseDialog isOpen={addHorseOpen} setOpen={setAddHorseOpen} clientId={props.clientId} />
      <ManageClientDialog isOpen={modifyClientOpen} setOpen={setModifyClientOpen} client={currentClient} />
    </div>
  );
};


export default Client;
