const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

const getDateAsString = (date: Date) => {
  const s = date.toISOString();
  const [year, month, day] = s.split('T')[0].split('-');
  return `${day} ${monthNames[Number(month) - 1]} ${year}`;
};

export default getDateAsString;
