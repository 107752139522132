import React from 'react';
import { Nutrients } from '../../redux/types';
import calculateDCABValue from '../../helpers/DCABCalculator';
import { reportSection, reportSectionTitle } from './horseReportStyle';
import NutritionRatiosReportGraph from '../NutritionRatiosReportGraph';

interface Props {
  combinedNetNutrients: Nutrients
  feedsAsDM: Nutrients
  totalFeedAmount: number
  minimalView?: boolean
}

const getCalciumPhosphorusRatio = (calciumValue: number, phosphorusValue: number, minimalView?: boolean, small?: boolean) => {
  const ratio: number = calciumValue / phosphorusValue;
  let informationalString: string = '';
  if (ratio < 1.5) {
    informationalString = `The calcium to phosphorus ratio is ${ratio.toFixed(1)}. The calcium to phosphorus ratio in the overall diet
    is low. This is not optimal for bone development and skeletal health. Speak to
    your Connolly’s RED MILLS nutritionist to resolve this problem.`;
    if (small) return [ratio.toFixed(1), 'Low', informationalString];
  } else if (ratio < 6) {
    informationalString = `The calcium to phosphorus ratio is ${ratio.toFixed(1)}. The calcium to phosphorus ratio in the overall diet
    is within the range required for optimal bone formation.`;
    if (small) return [ratio.toFixed(1), 'Good', informationalString];
  } else {
    informationalString = `The calcium to phosphorus ratio is ${ratio.toFixed(1)}. The calcium to phosphorus ratio in the overall
    diet high. This is not optimal for bone development and skeletal health.
    Speak to your Connolly’s RED MILLS nutritionist to resolve this problem.`;
    if (small) return [ratio.toFixed(1), 'High', informationalString];
  }
  return (
    <p key="calcium:phos">
      <b>Calcium: Phosphorus Ratio: </b>
      {minimalView ? `${ratio.toFixed(1)}` : informationalString}
    </p>
  );
};

const getZincCopperRatio = (ZincValue: number, CopperValue: number, minimalView?: boolean, small?: boolean) => {
  const ratio: number = ZincValue / CopperValue;
  let informationalString: string = '';
  if (ratio < 3.0) {
    informationalString = `The zinc to copper ratio is ${ratio.toFixed(1)}. The zinc to copper ratio in the overall diet is a lower than
    recommended.  Zinc and copper compete for the same absorption sites in the
    digestive tract. Hence, a low zinc to copper ratio may result in inadequate
    absorption of zinc.  Speak to your Connolly’s RED MILLS nutritionist to resolve
    this problem.`;
    if (small) return [ratio.toFixed(1), 'Low', informationalString];
  } else if (ratio < 5.0) {
    informationalString = `The zinc to copper ratio is ${ratio.toFixed(1)}. The zinc to copper ratio in the overall diet is within the
    recommended range.`;
    if (small) return [ratio.toFixed(1), 'Good', informationalString];
  } else {
    informationalString = `The zinc to copper ratio is ${ratio.toFixed(1)}. The zinc to copper ratio in the overall diet is higher than
    recommended. Copper and zinc compete for the same absorption sites in the
    digestive tract. Hence, a high zinc to copper ratio may result in inadequate
    absorption of copper. Speak to your Connolly’s RED MILLS nutritionist to resolve
    this problem.`;
    if (small) return [ratio.toFixed(1), 'High', informationalString];
  }
  return (
    <p key="zinc:copper">
      <b>Zinc: Copper Ratio: </b>
      {minimalView ? `${ratio.toFixed(1)}` : informationalString}
    </p>
  );
};

const getDCAB = (feedsAsDM: Nutrients, totalFeedAmount: number, minimalView?: boolean, small?: boolean) => {
  const DCAB = calculateDCABValue(feedsAsDM, totalFeedAmount) as number;
  const DCAB_FOR_GRAPH = calculateDCABValue(feedsAsDM, totalFeedAmount, true);
  let informationalString: string = '';
  if (DCAB > 1 && DCAB < 200) {
    informationalString = `The DCAB of this diet is ${DCAB.toFixed(0)} mEq/kg. DCAB relates to the balance of the
    minerals within the diet that have either an alkalising or acidifying effect. The
    DCAB of the diet is low. This could cause metabolic acidosis and increased
    calcium excretion, which may have adverse effects on skeletal development and
    muscle function. You should discuss this with your Connolly’s RED MILLS
    nutritionist.`;
    if (small) return [`${DCAB.toFixed(0)}`, 'Low', informationalString, DCAB_FOR_GRAPH];
  } else if (DCAB > 200) {
    informationalString = `The DCAB of this diet is ${DCAB.toFixed(0)} mEq/kg. DCAB relates to the balance of the
    minerals within the diet that have either an alkalising or acidifying effect.
    The DCAB of the overall diet is within the acceptable range.`;
    if (small) return [`${DCAB.toFixed(0)}`, 'Good', informationalString, DCAB_FOR_GRAPH];
  } else {
    informationalString = `It has not been possible to
    calculate the DCAB of the diet.`;
    if (small) return ['0', 'Unknown', informationalString, [0, 0, 0, 0, 0, 0, 0]];
  }
  return (
    <p key="dcab">
      <b>Dietary Cation Anion Balance (DCAB): </b>
      {minimalView ? `${DCAB.toFixed(0)}` : informationalString}
    </p>
  );
};

const getRatios = (combinedNetNutrients: Nutrients, feedsAsDM: Nutrients, totalFeedAmount: number, minimalView: boolean) => [
  getDCAB(feedsAsDM, totalFeedAmount, minimalView),
  getCalciumPhosphorusRatio(combinedNetNutrients.calcium.value, combinedNetNutrients.phosphorous.value, minimalView),
  getZincCopperRatio(combinedNetNutrients.zinc.value, combinedNetNutrients.copper.value, minimalView),
];

const HorseDietRatios : React.FC<Props> = (props: Props) => {
  const ratios = getRatios(props.combinedNetNutrients, props.feedsAsDM, props.totalFeedAmount, !!props.minimalView);
  return (
    <div style={!props.minimalView ? reportSection : { marginTop: '1em', minHeight: 'inherit' }}>
      <div style={reportSectionTitle}>Diet Ratios</div>
      {ratios}
    </div>
  );
};

export const HorseDietRatiosSmall : React.FC<Props> = (props: Props) => {
  const [dcabValue, dcabState, dcabInfo, dcabGraphValues]: any = getDCAB(props.combinedNetNutrients, props.totalFeedAmount, false, true);
  const [zcValue, zcState, zcInfo]: any = getZincCopperRatio(props.combinedNetNutrients.zinc.value, props.combinedNetNutrients.copper.value, false, true);
  const [cpValue, cpState, cpInfo]: any = getCalciumPhosphorusRatio(props.combinedNetNutrients.calcium.value, props.combinedNetNutrients.phosphorous.value, false, true);
  const titleStyle = {
    fontWeight: 800,
    fontSize: '14px',
    marginTop: '1em',
  };
  const stateStyle = {
    fontWeight: 500,
    fontSize: '13px',
    marignTop: '.5em',
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row', wordBreak: 'break-word' }}>
      <div style={{
        display: 'flex', flexDirection: 'column', margin: 'auto', marginTop: 0, marginRight: '3em', flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={dcabGraphValues}
            ratioValue={dcabValue}
            backgroundColors={
              [
                '#f1efaa',
                '#eae8b1',
                '#dfdc78',
                '#e3e287',
                '#d4d027',
                '#c3c038',
                '#b5b346',
              ]
            }
          />
        </div>
        <div style={titleStyle}>DCAB</div>
        <div style={stateStyle}>
          Result:&nbsp;
          {dcabState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {dcabInfo}
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginTop: 0,
        flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={
              [
                props.combinedNetNutrients.calcium.value,
                props.combinedNetNutrients.phosphorous.value,
              ]
            }
            ratioValue={cpValue}
            backgroundColors={['#8e58b6', '#af91c6']}
          />
        </div>
        <div style={titleStyle}>Calcium : Phosphorous</div>
        <div style={stateStyle}>
          Result:&nbsp;
          {cpState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {cpInfo}
        </div>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'column', margin: 'auto', marginTop: 0, marginLeft: '3em', flex: 1,
      }}
      >
        <div style={{ width: '66%', margin: 'auto' }}>
          <NutritionRatiosReportGraph
            contents={[props.combinedNetNutrients.zinc.value, props.combinedNetNutrients.copper.value]}
            ratioValue={zcValue}
            backgroundColors={['#7ce481', '#adecb0']}
          />
        </div>
        <div style={titleStyle}>Zinc : Copper</div>
        <div style={stateStyle}>
          Result:&nbsp;
          {zcState}
        </div>
        <div style={{ fontSize: '12px' }}>
          {zcInfo}
        </div>
      </div>
    </div>
  );
};


export default HorseDietRatios;
