import * as CSS from 'csstype';

export const A4_PAGE: CSS.Properties = { fontSize: '14px', minHeight: '1042px', height: '1042px', width: '695px', minWidth: '695px', maxWidth: '695px', flexDirection: 'column', display: 'flex' };

export const reportSection: CSS.Properties = {
  border: '3px solid black',
  borderRadius: '5px',
  margin: 'auto',
  padding: '20px',
  // backgroundColor: '#e7e6e6',
  width: '695px',
};

export const reportSectionTitle: CSS.Properties = {
  fontWeight: 'bolder',
  fontSize: '16px',
  marginBottom: '15px',
};

export const reportColumn: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
};

export const reportRow: CSS.Properties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const table: CSS.Properties = {
  marginBottom: '20px',
  width: '580px',
  maxWidth: '580px',
  borderStyle: 'solid',
  borderColor: 'grey',
  borderWidth: '1px',
  borderRadius: '5px',
  MozBorderRadius: '5px',
  WebkitBorderRadius: '5px',
  borderSpacing: '0',
  borderCollapse: 'separate',
};

export const lastTableStyle: CSS.Properties = {
  width: '580px',
  maxWidth: '580px',
  marginBottom: '76px',
  borderStyle: 'solid',
  borderColor: 'grey',
  borderWidth: '1px',
  borderRadius: '5px',
  MozBorderRadius: '5px',
  WebkitBorderRadius: '5px',
  borderSpacing: '0',
  borderCollapse: 'separate',
};

export const theadStyle: CSS.Properties = {
  color: 'white',
  fontWeight: 'bold',
};

export const poorTdStyling: CSS.Properties = {
  padding: '2px',
  fontWeight: 'bold',
};

export const tdStyle: CSS.Properties = {
  padding: '2px',
  fontWeight: 'bold',
  backgroundColor: '#e7e6e6',
};

export const tbodyp: CSS.Properties = {
  margin: '10px',
};
