import React from 'react';
import { Nutrients } from '../../redux/types';
import { reportSectionTitle, reportSection } from './horseReportStyle';

const VIT_K_TEXT = `
This diet contains added vitamin K. Vitamin K plays a role in blood
clotting, blood vessel health and bone metabolism.
`;

const VIT_C_TEXT = `
This diet contains added vitamin C. Vitamin C is a water-soluble
antioxidant which aids vitamin E recycling and plays an important role in immune
function.
`;

const BIOTIN_TEXT = `
This diet contains added biotin. Biotin supports hoof condition, skin and
coat health. Some horses may benefit from additional biotin in their ration.
`;

interface Props {
  nutrients: (keyof Nutrients)[]
  combinedNetNutrients: Nutrients
}

const getTextFor = (n: keyof Nutrients) => {
  switch (n) {
  case 'vitK':
    return (
      <p key={n}>
        <b>Vitamin K: </b>
        {VIT_K_TEXT}
      </p>
    );
  case 'vitC':
    return (
      <p key={n}>
        <b>Vitamin C: </b>
        {VIT_C_TEXT}
      </p>
    );
  case 'biotin':
    return (
      <p key={n}>
        <b>Biotin: </b>
        {BIOTIN_TEXT}
      </p>
    );
  default:
    return (
      <p key={n}>
        No info for
        {n}
      </p>
    );
  }
};

const displayComments = (nutrisToComment: (keyof Nutrients)[], combinedNetNutrients: Nutrients) => {
  const result: any = [];
  nutrisToComment.forEach((k) => {
    if (combinedNetNutrients[k].value > 0) {
      result.push(getTextFor(k));
    }
  });
  return result;
};

const HorsePresenceComments : React.FC<Props> = (props: Props) => {
  const comments: any = displayComments(props.nutrients, props.combinedNetNutrients);
  if (comments.length > 0) {
    return (
      <div style={{wordBreak: "break-word"}}>
        {comments}
      </div>
    );
  }
  return (
    <></>
  );
};

export default HorsePresenceComments;
