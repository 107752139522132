import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';

import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import firebase from 'firebase/app';
import App from './App';
import * as serviceWorker from './serviceWorker';

import rootReducer from './redux/root';
import firebaseConfig from './firebaseConfig';


const firebaseApp: firebase.app.App = firebase.initializeApp(firebaseConfig);
const firestore: firebase.firestore.Firestore = firebaseApp.firestore();
firestore.enablePersistence({ synchronizeTabs: true });


const persistConfig = {
  key: 'root',
  blacklist: ['addHorse', 'editAccount'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

ReactDOM.render(<Provider store={store}><PersistGate loading={null} persistor={persistor}><BrowserRouter><App /></BrowserRouter></PersistGate></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
