import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core';

interface Props {
  setOpen: Function
  isOpen: boolean
  callback: Function
}

const DeleteFeedDialog : React.FC<Props> = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Move Feed</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: 'red'}}>
          WARNING: Deleting a feed is irreversible and the deleted feed will become unrecoverable and removed from all diet plans! Delete feeds with caution.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => props.callback()}>Confirm</Button>
        <Button color="primary" onClick={() => props.setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFeedDialog;
