export const monthsToString = (months: number): string => {
  const years = Math.floor(months / 12);
  const modMonths = months % 12;
  let s = '';
  if (years !== 0) {
    s += `${years.toString()} year`;
    if (years > 1) s += 's';
    if (modMonths > 0) s += ` and ${modMonths} month${modMonths > 1 ? 's' : ''}`;
  } else {
    s += `${months} month`;
    if (months > 1) s += 's';
  }
  return s;
};

export const monthsToYearsMonths = (months:number) : [number, number] => {
  const years = Math.floor(months / 12);
  const modMonths = months % 12;
  return [years, modMonths];
};

export const yearsMonthsToMonths = (years: number, months: number) : number => years * 12 + months;
