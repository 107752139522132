const firebaseConfig = {
  apiKey: 'AIzaSyDHZatHreHm0McYKWzcuiFknie8aHK-6rQ',
  authDomain: 'equine-app-demo.firebaseapp.com',
  databaseURL: 'https://equine-app-demo.firebaseio.com',
  projectId: 'equine-app-demo',
  storageBucket: 'equine-app-demo.appspot.com',
  messagingSenderId: '641787242191',
  appId: '1:641787242191:web:d5fb1062298731a05c79ba',
};

export default firebaseConfig;
