import React, { useState } from 'react';
import firebase from 'firebase/app';
import {
  makeStyles, Theme, createStyles, Dialog, DialogTitle, DialogContentText, DialogContent, Button, DialogActions, TextField,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Client, UpdateClientName, UPDATE_CLIENT_NAME, RemoveClient, REMOVE_CLIENT,
} from '../redux/types';
import { RootState } from '../redux/root';
import {deleteHorse, deleteReport} from './DeleteHorseDialog';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '70ch',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '30ch',
  },
}));

const handleSaveChanges = (newName: string, client: Client, uid: string, setOpen: Function, dispatch: Function) => {
  const ref: firebase.firestore.DocumentReference = firebase.firestore()
    .collection('users')
    .doc(uid)
    .collection('clients')
    .doc(client.id);
  ref.set({
    name: newName,
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
  }, { merge: true });
  const action: UpdateClientName = {
    type: UPDATE_CLIENT_NAME,
    payload: {
      id: client.id,
      name: newName,
    },
  };
  dispatch(action);
  setOpen(false);
};

const handleDeleteClient = (client: Client, uid: string, history: any, dispatch: Function) => {
  client.horses.forEach((horse) => {
    deleteReport(horse.id, dispatch);
    deleteHorse(uid, client.id, horse.id, dispatch);
  });
  const ref: firebase.firestore.DocumentReference = firebase.firestore()
    .collection('users')
    .doc(uid)
    .collection('clients')
    .doc(client.id);
  ref.delete();
  const action: RemoveClient = {
    type: REMOVE_CLIENT,
    payload: client.id,
  };
  dispatch(action);
  history.push('/Clients');
};

interface Props {
  setOpen: Function
  isOpen: boolean
  client: Client
}


const ConfirmDeleteClientDialog: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const uid: string = useSelector((state: RootState) => state.user.id);
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Delete&nbsp;
        {props.client.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete&nbsp;
          {props.client.name}
          ? Doing so will remove any underlying reports or horses
          associated to the client.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDeleteClient(props.client, uid, history, dispatch)} color="primary">
          Confirm
        </Button>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const ManageClientDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [clientName, setClientName] = useState(props.client.name);
  const [deletionConfirmationOpen, setDeletionConfirmationOpen] = useState(false);
  const uid: string = useSelector((state: RootState) => state.user.id);
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Modify Client -
        &nbsp;
        {props.client.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Manage the properties of&nbsp;
          {props.client.name}
          .
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="clientNamet"
          label="Modify Client Name"
          type="text"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeletionConfirmationOpen(true)} color="secondary">
          Delete Client
        </Button>
        <Button onClick={() => handleSaveChanges(clientName, props.client, uid, props.setOpen, dispatch)} color="primary">
          Save Changes
        </Button>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
      <ConfirmDeleteClientDialog isOpen={deletionConfirmationOpen} setOpen={setDeletionConfirmationOpen} client={props.client} />
    </Dialog>
  );
};

export default ManageClientDialog;
