
import React, { useState } from 'react';
import {
  TextField, Button,
} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';

const attemptPasswordChange = (email: string, currentPassword: string, newPassword: string, newPasswordConf: string, setError: Function) => {
  if (newPassword !== newPasswordConf) return setError('Error - New passwords did not match.');
  const credential = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
  const user = firebase.auth().currentUser;
  user!.reauthenticateWithCredential(credential).then(() => {
    user!.updatePassword(newPassword).then(() => {
      setError('Password has been succesfully updated.');
    });
  }).catch((err) => setError(err.message));
};

const ChangeAccountPassword : React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConf, setNewPasswordConf] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const email = useSelector((state: RootState) => state.user.email);
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="Current Password"
        label="Current Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={currentPassword}
        onChange={(e) => { setCurrentPassword(e.target.value); }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="New Password"
        label="New Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={newPassword}
        onChange={(e) => { setNewPassword(e.target.value); }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="Confirm New Password"
        label="Confirm New Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={newPasswordConf}
        onChange={(e) => setNewPasswordConf(e.target.value)}
      />
      <Button color="primary" onClick={() => attemptPasswordChange(email, currentPassword, newPassword, newPasswordConf, setErrorMessage)}>
        Update Password
      </Button>
      {errorMessage || ''}
    </>
  );
};

export default ChangeAccountPassword;
