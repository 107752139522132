/* eslint-disable react/prop-types */
import React from 'react';
import '../styles/Flexbox.scss';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './Home';
import Login from './Login';
import { RootState } from '../redux/root';
import Clients from './Clients';
import Client from './Client';
import { Client as iClient } from '../redux/types';
import LoadingBackdrop from './LoadingBackdrop';
import HorseViewContainer from './HorseViewContainer';
import Feeds from './Feeds';
import AddFeed from './AddFeed';
import ViewFeed from './ViewFeed';
import EditFeed from './EditFeed';
import ForgotPassword from './auth/ForgotPassword';
import NewPassword from './auth/NewPassword';

const Logout = () => {
  if (firebase.apps.length > 0) firebase.auth().signOut();
  return <Redirect to="/Login" />;
};

const clientExists = (clients: iClient[], id: string) => {
  if (clients.find((client) => client.id === id)) return true;
  return false;
};

const displayHome = (userLoggedIn: boolean) => (userLoggedIn ? <Home /> : <Login />);

const Main : React.FC = () => {
  const loggedIn: boolean = useSelector((state : RootState) => state.user.loggedIn);
  const clients: iClient[] = useSelector((state : RootState) => state.clients.clients);
  return (
    <Switch>
      <Route exact path="/" render={() => displayHome(loggedIn)} />
      <Route exact path="/Home" render={() => displayHome(loggedIn)} />
      <Route exact path="/Login" render={() => <Login />} />
      <Route exact path="/Logout" render={() => Logout()} />
      <Route exact path="/ForgotPassword" render={() => <ForgotPassword />} />
      <Route exact path="/NewPassword" render={() => <NewPassword />} />
      {
        // Clients & Horses
      }
      <Route exact path="/Clients" render={() => <Clients />} />
      <Route exact path="/Client/:client" render={(props) => (clientExists(clients, props.match.params.client) ? <Client clientId={props.match.params.client as string} /> : <LoadingBackdrop />)} />
      <Route exact path="/Client/:client/Horse/:horse" render={(props) => (clientExists(clients, props.match.params.client) ? <HorseViewContainer clientId={props.match.params.client as string} horseId={props.match.params.horse as string} /> : <LoadingBackdrop />)} />
      {
        // Feeds
      }
      <Route exact path="/Feeds" render={() => <Feeds />} />
      <Route exact path="/Feeds/Add" render={() => <AddFeed />} />
      <Route exact path="/Feed/c/:category/b/:brand/f/:feed" render={(props) => <ViewFeed categoryId={props.match.params.category} brandId={props.match.params.brand} feedId={props.match.params.feed} />} />
      <Route exact path="/Feed/c/:category/b/:brand/f/:feed/edit" render={(props) => <EditFeed categoryId={props.match.params.category} brandId={props.match.params.brand} feedId={props.match.params.feed} />} />
    </Switch>
  );
};


export default Main;
