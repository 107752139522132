/* eslint-disable max-len */
import {
  NutritionReportAction, NutritionReport, SET_NUTRITION_REPORT, DELETE_NUTRITION_REPORT,
} from './types';

interface NutritionReportState {
  reports: Map<string, NutritionReport>
}
const defaultState: NutritionReportState = {
  reports: new Map(),
};

const nutritionReportReducer = (state: NutritionReportState = defaultState, action: NutritionReportAction) => {
  switch (action.type) {
  case (SET_NUTRITION_REPORT): {
    const newReports: Map<string, NutritionReport> = Object.assign(state.reports).set(action.payload[0], action.payload[1]);
    return { ...state, reports: newReports };
  }
  case (DELETE_NUTRITION_REPORT): {
    const newReports: Map<string, NutritionReport> = Object.assign(state.reports);
    newReports.delete(action.payload);
    return { ...state, reports: newReports };
  }
  default: {
    return { ...state };
  }
  }
};

export default nutritionReportReducer;
