import { chartTextHelpers } from '../../helpers/chartTextHelpers';
import { genericChartToInclude } from '../NutritionGenericReportGraph';

export const DISCALIMER_TEXT = `
The dietary recommendations above are based solely on the information supplied. Feed quantities may require adjustment
based on body condition, workload, the environment, and management conditions. If a horse is suffering from any clinical
condition then veterinary advice should be sought. Where necessary reference values for the typical nutritional values of forages
have been used. However, the nutrient and mineral content of forage is naturally very variable. The recommended values for
individual nutrients are based on a thorough review of scientific literature.`;

/* Branding Section */

export enum BrandingID {
  REDMILLS = 0,
  FORAN = 1,
  FORANREDMILLS = 2,
}

export interface brand {
  id: BrandingID
  name: string
  address: string
  headerImage: string
  logo: string
  socialMedia: string
}

export const REDMILLS_BRAND: brand = {
  id: 0,
  name: "Connolly's RED MILLS",
  address: 'Goresbridge, County Kilkenny, Ireland',
  headerImage: '/report/redmillsreportheader.png',
  logo: '/report/rm_logo.png',
  socialMedia: '/report/socialmedia.png',
};

export const FORAN_BRAND: brand = {
  id: 1,
  name: 'Foran Equine',
  address: 'Cherry Orchard Industrial Estate, Johnstown, Dublin 10, Ireland',
  headerImage: '/report/foran.jpg',
  logo: '/report/foran_logo.png',
  socialMedia: '/report/foran_socialmedia.png',
};

export const COBRANDING_BRAND: brand = {
  id: 2,
  name: "Connolly's Red Mills & Foran Equine",
  address: '',
  headerImage: '/report/cobranding.png',
  logo: '/report/cobranding.png',
  socialMedia: '/report/foran_socialmedia.png',
};

export const displayBrandInformation = (brandID: BrandingID) => {
  switch (brandID) {
  case (BrandingID.REDMILLS): {
    return REDMILLS_BRAND;
  }
  case (BrandingID.FORAN): {
    return FORAN_BRAND;
  }
  case (BrandingID.FORANREDMILLS): {
    return COBRANDING_BRAND;
  }
  default: return REDMILLS_BRAND;
  }
};

export const EPL_GRAPH: genericChartToInclude = [
  ['energy', chartTextHelpers('energy')],
  ['crudeProtein', chartTextHelpers('crudeProtein')],
  ['lysine', chartTextHelpers('lysine')],
];

export const MACRO_GRAPH: genericChartToInclude = [
  ['calcium', chartTextHelpers('calcium')],
  ['phosphorous', chartTextHelpers('phosphorous')],
  ['sodium', chartTextHelpers('sodium')],
  ['chloride', chartTextHelpers('chloride')],
  ['potassium', chartTextHelpers('potassium')],
  ['magnesium', chartTextHelpers('magnesium')],
];

export const TRACE_GRAPH: genericChartToInclude = [
  ['iron', chartTextHelpers('iron')],
  ['iodine', chartTextHelpers('iodine')],
  ['cobalt', chartTextHelpers('cobalt')],
  ['copper', chartTextHelpers('copper')],
  ['manganese', chartTextHelpers('manganese')],
  ['zinc', chartTextHelpers('zinc')],
  ['selenium', chartTextHelpers('selenium')],
];

export const VITAMINS_GRAPH: genericChartToInclude = [
  ['vitA', chartTextHelpers('vitA')],
  ['vitD', chartTextHelpers('vitD')],
  ['vitE', chartTextHelpers('vitE')],
];
