import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { Nutrients } from '../redux/types';
import { FeedWithAmount } from '../helpers/horsePercentageManger';

interface Props {
  feedsWithAmounts: FeedWithAmount[]
  height?: number
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

const NON_GRAPH_NUTRIENTS = ['fibre', 'sugar', 'fat', 'starch', 'sulphur', 'vitC', 'vitK', 'biotin'];
const GRAPH_NUTRIENTS = [
  'energy',
  'crudeProtein',
  'lysine',
  'calcium',
  'phosphorous',
  'sodium',
  'chloride',
  'potassium',
  'magnesium',
  'iron',
  'iodine',
  'cobalt',
  'copper',
  'manganese',
  'zinc',
  'selenium',
  'vitA',
  'vitD',
  'vitE',
];


const convertFeedsWithAmountsToDatasets = (feedsWithAmounts: FeedWithAmount[], nutrientsToInclude: string[]): ChartData => {
  const labels: string[] = [];
  const datasets: Dataset[] = [];
  const datasetColors: string[] = ['rgb(47,141,250)', 'rgb(31,208,191)', 'rgb(235,100,139)', 'rgb(248,199,83)', 'rgb(169,55,144)', 'rgb(4,149,135)', 'rgb(85,0,140)'];
  feedsWithAmounts.forEach((feed) => {
    const dataset: any = {};
    dataset.label = feed.name;
    dataset.data = [];
    nutrientsToInclude.forEach((key) => {
      console.log(key);
      dataset.data.push((feed.nutrients[key as keyof Nutrients].value * feed.amount).toFixed(2));
    });
    dataset.backgroundColor = new Array(dataset.data.length).fill(datasetColors.shift());
    datasets.push(dataset);
  });
  if (feedsWithAmounts.length !== 0) {
    nutrientsToInclude.forEach((key) => 
      labels.push(`${feedsWithAmounts[0].nutrients[key as keyof Nutrients].displayName} (${feedsWithAmounts[0].nutrients[key as keyof Nutrients].unit})`));
  }
  return {
    datasets,
    labels,
  };
};

const NutritionContributorsValuesGraph : React.FC<Props> = (props: Props) => {
  if (props.feedsWithAmounts !== undefined) {
    const include1 = [
      'energy',
      'crudeProtein',
      'lysine',
      'calcium',
      'phosphorous',
      'sodium',
      'chloride',
      'potassium',
      'magnesium',
    ];
    const data = convertFeedsWithAmountsToDatasets(props.feedsWithAmounts, include1);
    const include2 = [
      'iron',
      'iodine',
      'cobalt',
      'copper',
      'manganese',
      'zinc',
      'selenium',
      'vitE',
    ];
    const data2 = convertFeedsWithAmountsToDatasets(props.feedsWithAmounts, include2);
    const include3 = [
      'vitA',
      'vitD',
    ];
    const data3 = convertFeedsWithAmountsToDatasets(props.feedsWithAmounts, include3);
    return (
      <div style={{maxHeight: '900px'}}>
        <HorizontalBar
          height={140}
          data={data}

          options={{
            maintainAspectRatio: true,
            legend: {
              display: true,
              onClick: () => {},
            },
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true,
              }],
            },
          }}
        />
        <HorizontalBar
          height={140}
          data={data2}

          options={{
            maintainAspectRatio: true,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true,
              }],
            },
          }}
        />
        <HorizontalBar
          height={140}
          data={data3}

          options={{
            maintainAspectRatio: true,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true,
              }],
            },
          }}
        />
      </div>
    );
  }
  return (<p>Loading...</p>);
};

export default NutritionContributorsValuesGraph;
