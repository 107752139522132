/* eslint-disable no-param-reassign */
import React from 'react';
import { Feed, NutritionReport } from '../../redux/types';

interface Props {
  report: NutritionReport
  feeds: Map<string, Feed>
}

const convertFeedAmountSymbol = (amount: number, symbol: string): [string, string] => {
  if (amount < 1) {
    amount *= 1000;
    symbol = 'g';
  }
  return [amount.toString(), symbol];
};

const DietHorseFeeds: React.FC<Props> = (props: Props) => (
  <div style={{ fontSize: '13px', wordBreak: 'break-word' }}>
    {Array.from(props.report.feedAmounts.keys()).map((feedKey: any) => {
      const feed: any = props.feeds.get(feedKey);
      const [feedAmount, feedAmountSymbol] = convertFeedAmountSymbol(feed.amount, 'kg');
      return (
        <div key={feed.name}>
          <div>
            {feed.name}
            :
            &nbsp;
            {feedAmount}
            &nbsp;
            {feedAmountSymbol}
          </div>
        </div>
      );
    })}
  </div>
);

export default DietHorseFeeds;
