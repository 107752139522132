import React, { useEffect } from 'react';
import './styles/Flexbox.scss';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import CssBaseline from '@material-ui/core/CssBaseline';
import Navbar from './components/Navbar';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import Main from './components/Main';
import { fetchUserWithUID } from './components/Login';
import { UserLogout, USER_LOGOUT } from './redux/types';


function userLoggedOut(dispatch: Function) {
  const action: UserLogout = {
    type: USER_LOGOUT,
  };
  dispatch(action);
}


function setupAuth(dispatch: Function) {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) fetchUserWithUID(user.uid, dispatch);
    else {
      userLoggedOut(dispatch);
    }
  });
}

const App : React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => setupAuth(dispatch), []);
  return (
    <>
      <CssBaseline />
      <Navbar />
      <Main />
    </>
  );
};

export default App;
