import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Nutrients } from '../redux/types';
import 'chartjs-plugin-labels';


interface Props {
  contributors: Nutrients
}

interface Dataset {
  label: string,
  data: number[]
  backgroundColor?: string[]
}

interface ChartData {
  datasets: Dataset[]
  labels: string[]
}

/*
* Takes a list of numbers and an index and returns the percentage of the number at the given index.
*/
const calculatePercent = (list: number[], index: number) => {
  const num = list[index];
  return ((num / list.reduce((x: number, y: number) => x + y)) * 100).toFixed(2);
};

const convertContributorsToChartData = (contributors: Nutrients) => {
  const data = [contributors.fibre.value, contributors.starch.value, contributors.crudeProtein.value, contributors.sugar.value, contributors.fat.value ];
  const dataset: Dataset = {
    label: 'Macros - The Breakdown',
    data,
    backgroundColor: ['#88e78d', '#d8d57d', '#b091c7', '#76bdbd', '#ecbdec'],
  };
  return {
    datasets: [dataset],
    labels: [
      contributors.fibre.displayName,
      contributors.starch.displayName,
      contributors.crudeProtein.displayName,
      contributors.sugar.displayName,
      contributors.fat.displayName,
    ],
  };
};

const customLegend = (data: any) => (
  <div style={{
    display: 'flex', flexDirection: 'column', marginLeft: '-10em', minWidth: '200px',
  }}
  >
    <ul>
      {(data.labels as string[]).map((label, i) => (
        <li style={{fontWeight: 800}} key={label}>
          <span style={{ color: data.datasets[0].backgroundColor[i], fontSize: '25px' }}>&#9632;&nbsp;</span>
          {label}
        </li>
      ))}
    </ul>
  </div>
);

const NutritionMacrosBreakdownGraph2 : React.FC<Props> = (props: Props) => {
  if (props.contributors !== undefined) {
    const data = convertContributorsToChartData(props.contributors);
    const options = {
      plugins: {
        labels: {
          render: 'percentage',
        },
      },
      legend: {
        display: false,
      },
    };
    return (
      <div style={{ display: 'flex' }}>
        <Doughnut
          height={0.2}
          width={0.1}
          data={data}
          options={options}
        />
        {customLegend(data)}
      </div>
    );
  }
  return (<p>Loading...</p>);
};

export default NutritionMacrosBreakdownGraph2;
