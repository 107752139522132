/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { RootState } from '../redux/root';
import {
  User, accountType, UserLogin, USER_LOGIN, Client, UpdateClients, UPDATE_CLIENTS,
} from '../redux/types';

interface FireStoreUser {
  accountType: accountType
  creationDate: firebase.firestore.Timestamp,
  email: string,
  name: string,
  phoneNumber: string,
  role: string,
}

export interface FireStoreClient {
  name: string,
  creationDate: firebase.firestore.Timestamp,
  lastUpdated: firebase.firestore.Timestamp
}

function fetchUsersClients(uid: string, dispatch: Function) {
  firebase.firestore()
    .collection('users')
    .doc(uid)
    .collection('clients')
    .get()
    .then((resp) => {
      if (!resp.empty) {
        const clients: Client[] = [];
        resp.docs.forEach((doc) => {
          const fClient: FireStoreClient = doc.data() as FireStoreClient;
          const client: Client = {
            id: doc.id,
            name: fClient.name,
            creationDate: fClient.creationDate.toDate(),
            lastUpdated: fClient.lastUpdated.toDate(),
            horses: [],
          };
          clients.push(client);
        });
        const action: UpdateClients = {
          type: UPDATE_CLIENTS,
          payload: clients,
        };
        dispatch(action);
      }
    });
}

export function fetchUserWithUID(uid: string, dispatch: Function) {
  firebase.firestore()
    .collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      const fsu: FireStoreUser = doc.data() as FireStoreUser;
      const user: User = {
        id: uid,
        loggedIn: true,
        email: fsu.email,
        name: fsu.name,
        phoneNumber: fsu.phoneNumber,
        role: fsu.role,
        creationDate: fsu.creationDate.toDate(),
        accountType: fsu.accountType,
      };
      const action: UserLogin = {
        type: USER_LOGIN,
        payload: user,
      };
      dispatch(action);
      fetchUsersClients(uid, dispatch);
    });
}

const attemptLogin = (email: string, password: string, dispatch: Function, setErrorState: Function) => {
  firebase.auth().signInWithEmailAndPassword(email, password).then((reply: any) => {
    const { uid } = reply.user;
    fetchUserWithUID(uid, dispatch);
  }).catch((error) => setErrorState(error));
};

// View Stuff

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
  },
}));


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to="mailto:seanfradl@gmail.com">
        Seán Fradl
      </Link>
      &nbsp;
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

interface firebaseError {
  message: string
}

const Login : React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const loggedIn: boolean = useSelector((state : RootState) => state.user.loggedIn);
  const [errorState, setErrorState] : [firebaseError | undefined, Function] = useState(undefined);
  const classes: any = useStyles();
  if (loggedIn) return <Redirect to="/" />;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Typography>
            {errorState !== undefined ? errorState!.message : ''}
          </Typography>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => { e.preventDefault(); attemptLogin(email, password, dispatch, setErrorState); }}
          >
            Sign In
          </Button>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="caption" display="block" gutterBottom>
                By signing into this instance you highlight you agree to the&nbsp;
                <a href="https://docs.google.com/document/d/1iEVeT6vcd39rO6aVtURUQOJWsg1paEmgVTS1JSvVpcw/edit?usp=sharing">
                  Terms of Service
                </a>
                . Last Updated 03/08/2020
              </Typography>
            </Grid>
            <Grid item xs>
              <Link to="/ForgotPassword" className={classes.link}>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>

      <Box mt={8}>
        <div style={{ flexDirection: 'column', textAlign: 'center' }}>
          <img src="/eqa.png" style={{ height: '80px', marginBottom: '10px' }} alt="Equine App Logo" />
          <Copyright />
        </div>
      </Box>
    </Container>
  );
};


export default Login;
