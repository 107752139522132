/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { RootState } from '../../redux/root';
import {
  accountType,
} from '../../redux/types';

interface FireStoreUser {
  accountType: accountType
  creationDate: firebase.firestore.Timestamp,
  email: string,
  name: string,
  phoneNumber: string,
  role: string,
}

export interface FireStoreClient {
  name: string,
  creationDate: firebase.firestore.Timestamp,
  lastUpdated: firebase.firestore.Timestamp
}

const attemptReset = (email: string, setErrorState: Function) => {
  if (email) {
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      setErrorState(`An email has been sent to ${email} with a password reset link. Please check your email.`);
    }).catch((e:any) => setErrorState(e.message));
  } else {
    setErrorState('An email is required to send a password reset email to.');
  }
};

// View Stuff


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
  },
}));


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to="mailto:seanfradl@gmail.com">
        Seán Fradl
      </Link>
      &nbsp;
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

interface firebaseError {
  message: string
}

const ForgotPassword : React.FC = () => {
  const [email, setEmail] = useState('');
  const loggedIn: boolean = useSelector((state : RootState) => state.user.loggedIn);
  const [errorState, setErrorState] : [string, Function] = useState('');
  const classes: any = useStyles();
  if (loggedIn) return <Redirect to="/" />;
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </form>
        <Typography>
          {errorState}
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={(e) => { e.preventDefault(); attemptReset(email, setErrorState); }}
        >
          Reset
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};


export default ForgotPassword;
