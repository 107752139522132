import { combineReducers } from 'redux';
import userReducer from './user';
import clientsReducer from './clients';
import addHorseReducer from './addHorse';
import horseSubNavigationReducer from './horseSubNavigation';
import feedsReducer from './feeds';
import addFeedReducer from './addFeed';
import nutritionReportReducer from './nutritionReport';
import currentReportNutritionReducer from './currentNutritionReport';
import editAccountReducer from './editAccount';


const rootReducer = combineReducers({
  clients: clientsReducer,
  user: userReducer,
  addHorse: addHorseReducer,
  horseSubNavigation: horseSubNavigationReducer,
  feeds: feedsReducer,
  addFeed: addFeedReducer,
  nutritionReports: nutritionReportReducer,
  currentNutritionReport: currentReportNutritionReducer,
  editAccount: editAccountReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
