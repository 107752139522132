/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core';

import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState, convertToRaw, convertFromRaw,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
  reportSection, reportSectionTitle,
} from './viewReport/horseReportStyle';
import {
  EditableReportSection, SetEditableReportSection, SET_EDITABLE_REPORT_SECTION, EditableReportSectionID,
} from '../redux/types';

interface Props {
  editableReportSection: EditableReportSection
  setEditDiaogOpen: Function
  sectionTitle: string
  isNewReport?: boolean
}

const updateEditorSection = (sectionID: EditableReportSectionID, editorState: EditorState, dispatch: Function) => {
  const eds = {
    content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    sectionID,
  };
  const action: SetEditableReportSection = {
    type: SET_EDITABLE_REPORT_SECTION,
    payload: eds,
  };
  dispatch(action);
};

const EditableReportSectionComponent : React.FC<Props> = (props: Props) => (
  <div className="editable" style={props.isNewReport ? {wordBreak: 'break-word'} : reportSection} onClick={() => props.setEditDiaogOpen(true)} role="clickable">
    {props.isNewReport ? '' : <div style={reportSectionTitle}>{props.sectionTitle}</div> }
    {parse(draftToHtml(JSON.parse(props.editableReportSection.content)))}
  </div>
);

/* <EditReportSectionDialog saveEditorState={saveEditorState} isOpen={isOpen} setOpen={setOpen} saveWithContent={() => {}} editorState={editorState} /> */
interface EditReportSectionDialogProps{
  setOpen: Function
  editorContent: string
  editableSectionID: EditableReportSectionID
  isOpen: boolean;
}

export const EditReportSectionDialog : React.FC<EditReportSectionDialogProps> = (props: EditReportSectionDialogProps) => {
  let editor: EditorState;
  useEffect(() => { editor = EditorState.createEmpty(); }, [props.editorContent]);
  if (props.editorContent) {
    editor = EditorState.createWithContent(convertFromRaw(JSON.parse(props.editorContent)));
  } else {
    editor = EditorState.createEmpty();
  }
  const [editorState, setEditorState] = useState();
  const dispatch = useDispatch();
  return (
    <Dialog key={props.editorContent} open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Edit Report Section
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Below you may make changes to the selected report section.
        </DialogContentText>
        <Editor
          editorState={editorState || editor}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(newState) => setEditorState(newState)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => updateEditorSection(props.editableSectionID, editorState || editor, dispatch)} color="primary">
          Save Changes
        </Button>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default EditableReportSectionComponent;
