/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { User } from '../../redux/types';
import {
  reportColumn, reportRow,
} from './horseReportStyle';

export interface FooterReportBranding {
  name: string,
  location: string,
  socialMedia: string,
  logoBottom: string,
}

interface Props {
  user: User
  brandingInfo: FooterReportBranding
}

const HorseFooterBranding : React.FC<Props> = (props: Props) => (
  <div style={reportRow}>
    <div style={{ ...reportColumn, fontSize: '12px' }}>
      <div>
        {props.brandingInfo.name}
      </div>
      <div>
        {props.brandingInfo.location}
      </div>
      <div>
        Consultant:
        &nbsp;
        {props.user.name}
      </div>
      <div>
        Consultant&apos;s Phone:
        &nbsp;
        {props.user.phoneNumber}
      </div>
      <div>
        Consultant&apos;s Email:
        &nbsp;
        {props.user.email}
      </div>
    </div>
    <div style={reportColumn}>
      <div style={reportRow}>
        {/*<img src='/eqa.png' style={{ height: '80px' }} /> */}
        <img src={props.brandingInfo.socialMedia} style={{ height: '80px' }} />
        <img src={props.brandingInfo.logoBottom} style={{ height: '80px' }} />
      </div>
    </div>
  </div>
);

export default HorseFooterBranding;
