/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Horse, NutritionReport, Feed, Nutrients, SpecialNutritionalBounds, User, EditableReportSection, defaultNutrients,
} from '../../redux/types';
import { NutrientRequirementsOutput } from '../../helpers/nutrientRequirementWrapper';
import ReportBox, { NutrientRowItem } from './ReportBox';
import { HorseReportStarchInfoSmall } from '../viewReport/HorseReportStarchInfo';
import DietHorseInfo from './DietHorseInfo';
import DietHorseFeeds from './DietHorseFeeds';
import NutritionOverviewGraph from '../NutritionOverviewGraph';
import getDateAsString from '../../helpers/dateHelper';
import NutritionContributorsGraph from '../NutritionContributorsGraph';
import DietHorseTable from './DietHorseTable';
import NutritionMacrosBreakdownGraph2 from '../NutritionMacrosBreakdownGraph2';
import { HorseReportFibreInfo2 } from '../viewReport/HorseReportFibreInfo';
import { HorseReportProteinInfoSmall } from '../viewReport/HorseReportProteinInfo';
import HorseReportSugarInfo from '../viewReport/HorseReportSugarInfo';
import { HorseReportFatsInfoSmall } from '../viewReport/HorseReportFatsInfo';
import { HorseDietRatiosSmall } from '../viewReport/HorseDietRatios';
import HorseFooterBranding from '../viewReport/HorseFooterBranding';
import {
  DISCALIMER_TEXT, BrandingID, displayBrandInformation,
  EPL_GRAPH, MACRO_GRAPH, TRACE_GRAPH, VITAMINS_GRAPH,
} from './ReportConstants';
import EditableReportSectionComponent, { EditReportSectionDialog } from '../EditableReportSectionComponent';
import { commentsSectionTemplate } from '../../helpers/editableReportSectionsHelper';
import NutritionGenericReportGraph from '../NutritionGenericReportGraph';
import HorsePresenceComments from '../viewReport/HorsePresenceComments';


interface Props {
  horse: Horse
  report: NutritionReport
  feeds: Map<string, Feed>
  combinedNetNutrients: Nutrients
  currentHorseRequirements: NutrientRequirementsOutput
  contributors: [string, Nutrients][] | undefined
  feedsAsDM: Nutrients
  percentages: Nutrients
  specialBounds: SpecialNutritionalBounds
  roughageAmount: number
  user: User
  brandingID: BrandingID
  totalFeedAmount: number
}

export const A4_PAGE: CSSProperties = {
  fontSize: '12px',
  minHeight: '1042px',
  height: '1042px',
  width: '695px',
  minWidth: '695px',
  maxWidth: '695px',
  flexDirection: 'column',
  display: 'flex',
  fontVariantLigatures: 'none',
  backgroundColor: 'white',
};

const LongReport : React.FC<Props> = (props: Props) => {
  const commentsReportSection: EditableReportSection = props.report.editableReportSections.get(3) ? props.report.editableReportSections.get(3) as EditableReportSection : { content: `${commentsSectionTemplate}`, sectionID: 3 };
  const titleReportSection: EditableReportSection = props.report.editableReportSections.get(0) ? props.report.editableReportSections.get(0) as EditableReportSection : { content: `${commentsSectionTemplate}`, sectionID: 0 };
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [editDialogContent, setEditDialogContent] = useState('');
  const [editDialogSID, setEditDialogSID] = useState(-1);
  const brand = displayBrandInformation(props.brandingID);
  return (
    <div>
      <EditReportSectionDialog
        key={editDialogSID}
        isOpen={editDialogIsOpen}
        setOpen={setEditDialogIsOpen}
        editorContent={editDialogContent}
        editableSectionID={editDialogSID}
      />
      <div id="page-1" style={A4_PAGE}>
        <img src={brand.headerImage} alt="branding" style={{ height: '143px', width: '650px', margin: '20px' }} />
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bolder' }}>{props.report.title}</div>
          <div style={{ fontSize: '18px' }}>{getDateAsString(new Date())}</div>
        </div>
        <div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>
          <ReportBox title="Horse Profile" fabIcon="info" fabColor="#7fd985" child={<DietHorseInfo horse={props.horse} />} />
          <ReportBox
            title="Feed Intake"
            style={{
              marginTop: '0', marginBottom: '0', marginRight: '0', marginLeft: '2em',
            }}
            fabIcon="feed"
            fabColor="#d0cd6a"
            child={<DietHorseFeeds feeds={props.feeds} report={props.report} />}
          />
        </div>
        <ReportBox
          title="&nbsp;"
          fabIcon="pen"
          fabColor="#a176c2"
          child={(
            <>
              <EditableReportSectionComponent
                isNewReport
                sectionTitle=""
                editableReportSection={titleReportSection}
                setEditDiaogOpen={() => {
                  setEditDialogContent(titleReportSection.content);
                  setEditDialogSID(titleReportSection.sectionID);
                  setEditDialogIsOpen(true);
                }}
              />
            </>
          )}
        />
      </div>
      <div id="page-2" style={A4_PAGE}>

        {props.contributors ? (
          <ReportBox
            noMargin
            title="Feed Contributors"
            fabIcon="chart"
            fabColor="#ecbdec"
            child={(
              <div style={{
                margin: 'auto',
                width: '80%',
              }}
              >
                <NutritionContributorsGraph
                  height={250}
                  contributors={props.contributors}
                />
              </div>
            )}
          />
        )
          : ''}
        <ReportBox
          noMargin
          title="Table of Nutrients"
          fabIcon="scales"
          fabColor="#76bdbd"
          child={(
            <DietHorseTable
              combinedNetNutrients={props.combinedNetNutrients}
              currentHorseRequirements={props.currentHorseRequirements}
              specialBounds={props.specialBounds}
            />
          )}
        />
      </div>
      <div id="page-3" style={A4_PAGE}>
        <ReportBox
          noMargin
          title=""
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionMacrosBreakdownGraph2
                contributors={props.combinedNetNutrients}
              />
              <div style={{fontSize: '18px', fontWeight: 'bolder', marginLeft: '71px'}}>Macronutrients</div>
              <NutrientRowItem
                title=""
                fabIcon="pastures"
                fabColor="#88e78d"
                child={(
                  <HorseReportFibreInfo2
                    horse={props.horse}
                    roughageAmount={props.roughageAmount}
                  />
                )}
              />
              <NutrientRowItem
                title=""
                fabIcon="grain"
                fabColor="#d8d57d"
                child={(
                  <HorseReportStarchInfoSmall
                    horse={props.horse}
                    contributors={props.combinedNetNutrients}
                  />
                )}
              />
              <NutrientRowItem
                title=""
                fabIcon="logistics"
                fabColor="#b091c7"
                child={
                  <HorseReportProteinInfoSmall contributors={props.combinedNetNutrients} />
                }
              />
              <NutrientRowItem
                title=""
                fabIcon="honey"
                fabColor="#76bdbd"
                child={
                  <HorseReportSugarInfo small contributors={props.combinedNetNutrients} />
                }
              />
              <NutrientRowItem
                title=""
                fabIcon="sunflowerb"
                fabColor="#ecbdec"
                child={
                  <HorseReportFatsInfoSmall contributors={props.combinedNetNutrients} />
                }
              />
            </>
          )}
        />
      </div>
      <div id="page-4" style={A4_PAGE}>
        <ReportBox
          title="Diet Ratios"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <HorseDietRatiosSmall
                feedsAsDM={props.feedsAsDM}
                totalFeedAmount={props.totalFeedAmount}
                combinedNetNutrients={props.combinedNetNutrients}
              />
            </>
          )}
        />
        <ReportBox
          title="Energy, Protein and Lysine"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={EPL_GRAPH}
                height={175}
              />
            </>
          )}
        />

      </div>
      <div id="page-5" style={A4_PAGE}>
        <ReportBox
          title="Macro Minerals"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={MACRO_GRAPH}
              />
            </>
          )}
        />
      </div>
      <div id="page-6" style={A4_PAGE}>
        <ReportBox
          title="Trace Minerals"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={TRACE_GRAPH}
              />
            </>
          )}
        />
      </div>
      <div id="page-7" style={A4_PAGE}>
        <ReportBox
          title="Vitamins"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <NutritionGenericReportGraph
                percentages={props.percentages}
                specialBounds={props.specialBounds}
                toInclude={VITAMINS_GRAPH}
                height={175}
              />
            </>
          )}
        />
        <ReportBox
          title="Additional Vitamins"
          fabIcon=""
          fabColor=""
          child={(
            <>
              <HorsePresenceComments
                combinedNetNutrients={props.combinedNetNutrients}
                nutrients={['vitK', 'vitC', 'biotin']}
              />
            </>
          )}
        />
      </div>
      <div id="page-8" style={A4_PAGE}>
        <ReportBox
          noMargin
          title="Nutrient Overview"
          fabIcon="report"
          fabColor="#a176c2"
          child={(
            <div style={{ margin: 'auto', width: '80%' }}>
              <NutritionOverviewGraph
                height={200}
                percentages={props.percentages}
                specialBounds={props.specialBounds}
              />
            </div>
          )}
        />
        <ReportBox
          title="Report Highlights"
          fabIcon="idea"
          fabColor="#81d885"
          child={(
            <>
              <EditableReportSectionComponent
                isNewReport
                sectionTitle="Consultant’s Comments"
                editableReportSection={commentsReportSection}
                setEditDiaogOpen={() => {
                  setEditDialogContent(commentsReportSection.content);
                  setEditDialogSID(commentsReportSection.sectionID);
                  setEditDialogIsOpen(true);
                }}
              />
            </>
          )}
        />
        <p style={{ fontSize: '10px' }}>
          <b>DISCLAIMER: </b>
          {DISCALIMER_TEXT}
        </p>
        <div style={{ marginTop: 'auto' }}>
          <HorseFooterBranding
            user={props.user}
            brandingInfo={{
              name: brand.name,
              location: brand.address,
              socialMedia: brand.socialMedia,
              logoBottom: brand.logo,
            }}
          />
        </div>
      </div>
    </div>
  );
};


export default LongReport;
