/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, Props } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material Ui Stuffs
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Grid, Fab, IconButton, Select, InputLabel, FormControl, Button, DialogTitle, DialogContentText, Dialog, DialogContent, DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import firebase from 'firebase/app';
import { Link as RouterLink } from 'react-router-dom';
import { RootState } from '../redux/root';

import {
  Feeds, Feed, SET_FEED_CATEGORIES, setFeedCategories, setFeedBrands, SET_FEED_BRANDS, addFeed, ADD_FEED, addFeedBrand, ADD_FEED_BRAND,
} from '../redux/types';

const LOCALE: string = 'en-GB';

const useStyles = makeStyles((theme) => ({
  '@global': {
    i: {
      fontSize: '2em',
    },
  },
  table: {
    margin: 'auto',
    maxWidth: '50em',
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    margin: 'auto',
    display: 'flex',
    alignContent: 'center',
    maxWidth: '50em',
  },
  fab: {
    margin: theme.spacing(2),
  },
  root: {
    margin: theme.spacing(2),
    marginTop: '3em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
}));


const displayFeeds = (feeds: Feed[]) => {
  const result: JSX.Element[] = [];
  feeds.map((feed: Feed) => {
    result.push(
      <TableRow key={feed.id}>
        <TableCell>{feed.name}</TableCell>
        <TableCell>{feed.lastUpdated.toLocaleDateString(LOCALE)}</TableCell>
        <TableCell>
          <IconButton component={RouterLink} to={`/Feed/c/${feed.categoryId}/b/${feed.brandId}/f/${feed.id}`} size="small" color="secondary">
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
        </TableCell>
      </TableRow>,
    );
    return undefined;
  });
  return result;
};

function displayFilter(filter: string, setFilter: Function) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="normal"
      id="filter"
      label="Filter"
      name="filter"
      autoComplete="filter"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}

export function getSelectors(dispatch: Function) {
  const categories: Map<string, string> = new Map();
  const brands: [string, string, string][] = [];
  firebase.firestore()
    .collection('feeds').get().then((resp) => {
      if (!resp.empty) {
        resp.docs.forEach(async (categoryDoc) => {
          const category: {name: string} = categoryDoc.data() as {name: string};
          categories.set(categoryDoc.id, category.name);
          categoryDoc.ref.collection('groups').get().then((brandResp) => {
            if (!brandResp.empty) {
              brandResp.docs.forEach((brandDoc) => {
                const brandData: {name: string} = brandDoc.data() as {name: string};
                brands.push([brandDoc.id, brandData.name, categoryDoc.id]);
              });
            }
          });
        });
        const a1: setFeedCategories = {
          type: SET_FEED_CATEGORIES,
          payload: categories,
        };
        dispatch(a1);
        const a2: setFeedBrands = {
          type: SET_FEED_BRANDS,
          payload: brands,
        };
        dispatch(a2);
      }
    });
}

export const displayCategorySelector = (feeds: Feeds, selectorState: [string, string], setSelectorState: Function) => (
  <FormControl style={{ minWidth: '40%' }}>
    <InputLabel htmlFor="category">Category</InputLabel>
    <Select
      native
      autoFocus
      fullWidth
      value={selectorState[0]}
      inputProps={{
        name: 'feed category selector',
        id: 'category',
      }}
      onChange={(e) => { setSelectorState([e.target.value, '-1']); }}
    >
      <option aria-label="None" value="-1" />
      {Array.from(feeds.categories).map(([key, value]) => <option aria-label="None" key={key} value={key}>{value}</option>)}
    </Select>
  </FormControl>

);

export const displayBrandSelector = (feeds: Feeds, selectorState: [string, string], setSelectorState: Function) => (
  <FormControl style={{ minWidth: '40%' }}>
    <InputLabel htmlFor="brand">Brand</InputLabel>
    <Select
      native
      fullWidth
      disabled={selectorState[0] === '-1'}
      value={selectorState[1]}
      inputProps={{
        name: 'brand selector',
        id: 'brand',
      }}
      onChange={(e) => { setSelectorState([selectorState[0], e.target.value]); }}
    >
      <option aria-label="None" value="-1" />
      {feeds.brands.map((brand) => (brand[2] === selectorState[0] ? <option aria-label="None" key={brand[0]} value={brand[0]}>{brand[1]}</option> : undefined))}
    </Select>
  </FormControl>
);

export const getFeeds = (feeds: Feeds, selectorState: [string, string], dispatch: Function) => {
  firebase.firestore().collection('feeds').doc(selectorState[0]).collection('groups')
    .doc(selectorState[1])
    .collection('feeds')
    .get()
    .then((resp) => {
      if (!resp.empty) {
        // Feeds found
        resp.docs.forEach((feedDoc) => {
          const feedData = feedDoc.data();
          feedData.lastUpdated = feedData.lastUpdated.toDate();
          feedData.creationDate = feedData.creationDate.toDate();
          [feedData.categoryId, feedData.brandId] = selectorState;
          feedData.id = feedDoc.id;
          feedData.ref = feedDoc.ref;
          const newFeed: Feed = feedData as Feed;
          const action: addFeed = {
            type: ADD_FEED,
            payload: [feedDoc.id, newFeed],
          };
          dispatch(action);
        });
      } else {
        console.error('Uh oh');
      }
    });
};

const FeedsComponent : React.FC = () => {
  const [filter, setFilter] = useState('');
  const [selectorState, setSelectorState] = useState(['-1', '-1']);
  const dispatch = useDispatch();
  useEffect(() => getSelectors(dispatch), []);
  const feeds: Feeds = useSelector((state: RootState) => state.feeds);
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  let visibleFeeds = Array.from(feeds.feeds.values());
  useEffect(() => getFeeds(feeds, selectorState as [string, string], dispatch), [selectorState[1]]);
  if (filter) {
    // eslint-disable-next-line max-len
    visibleFeeds = visibleFeeds.filter((feed) => (feed.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1));
  }
  if (selectorState[1] !== '-1') {
    visibleFeeds = visibleFeeds.filter((feed) => feed.categoryId === selectorState[0] && feed.brandId === selectorState[1]);
  } else if (visibleFeeds.length > 20) {
    visibleFeeds = visibleFeeds.slice(0, 20);
  }
  visibleFeeds.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.grid}>
        Feeds
      </Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={10}>
          <Button color="secondary" onClick={() => setOpen(true)}>
            Add New Brand
          </Button>
          <AddNewBrandDialog isOpen={isOpen} setOpen={setOpen} feeds={feeds} />
        </Grid>
        <Grid item xs={10}>
          <Typography component="p" style={{ marginBottom: '5px' }}>Select a category and brand to continue.</Typography>
        </Grid>
        <Grid item xs={10}>
          {displayCategorySelector(feeds, selectorState as [string, string], setSelectorState)}
          {displayBrandSelector(feeds, selectorState as [string, string], setSelectorState)}
        </Grid>
        <Grid item xs={10}>
          {displayFilter(filter, setFilter)}
        </Grid>
        <Grid item xs={2}>
          <Fab className={classes.fab} component={RouterLink} to="/Feeds/Add" color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayFeeds(visibleFeeds)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>

  );
};

interface AddNewBrandDialogProps {
  isOpen: boolean
  setOpen: Function
  feeds: Feeds
}

const addBrand = (selectorState: [string, string], dispatch: Function, setOpen: Function) => {
  const ref: firebase.firestore.DocumentReference = firebase.firestore()
    .collection('feeds')
    .doc(selectorState[0]).collection('groups')
    .doc(selectorState[1]);
  ref.set({
    name: selectorState[1],
  });
  const action: addFeedBrand = {
    type: ADD_FEED_BRAND,
    payload: [selectorState[1], selectorState[1], selectorState[0]],
  };
  dispatch(action);
  setOpen(false);
};

const AddNewBrandDialog: React.FC<AddNewBrandDialogProps> = (props: AddNewBrandDialogProps) => {
  const [newBrandName, setNewBrandName] = useState('');
  const [selectorState, setSelectorState] = useState(['-1', '-1']);
  const dispatch = useDispatch();
  return (
    <Dialog open={props.isOpen} onClose={() => props.setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Add New Brand
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select Brand Category
        </DialogContentText>
        <FormControl>
          {displayCategorySelector(props.feeds, selectorState as [string, string], setSelectorState)}
          <TextField
            margin="dense"
            id="Brand Name"
            label="Brand Name"
            type="text"
            value={newBrandName}
            onChange={(e) => setNewBrandName(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={selectorState[0] === '-1'} onClick={() => addBrand([selectorState[0], newBrandName], dispatch, props.setOpen)} color="primary">
          Add New Brand to
          &nbsp;
          {selectorState[0] === '-1' ? '' : selectorState[0]}
        </Button>
        <Button onClick={() => props.setOpen(false)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default FeedsComponent;
