import React from 'react';

import {
  Nutrients, Horse,
} from '../../redux/types';
import { table, theadStyle, tbodyp } from './horseReportStyle';


interface Props {
  contributors: Nutrients
  horse: Horse
}

const calculateStarchEvaluation = (netContributions: Nutrients, horse: Horse) => {
  const starchValue: number = Math.round((netContributions.starch.value / horse.weight) * 1e2) / 1e2;
  if (starchValue > 6) {
    return `The diet contains a very high level of starch (${starchValue}g per kg BW). Divide grain feeds into at least four meals per day.`;
  } if (starchValue > 4) {
    return `The diet contains a high level of starch (${starchValue}g per kg BW). Divide grain feeds into at least three meals per day.`;
  } if (starchValue > 2) {
    return `The diet contains a moderate level of starch (${starchValue}g per kg BW). Divide grain feeds into at least two meals per day.`;
  }
  return `The diet contains a low level of starch (${starchValue}g per kg BW).`;
};

const HorseReportStarchInfo : React.FC<Props> = (props: Props) => (
  <div>
    <p style={tbodyp}>
      Starch is efficiently digested in horse’s small intestine when fed in small amounts per meal. Feeding large amounts of
      starch in each meal can cause undigested starch to enter the hindgut. Health problems can arise when the large
      intestine is overloaded with undigested starch and could result in colic or laminitis. Feeding smaller grain meals more
      frequently can help reduce this risk.
    </p>
    <p style={tbodyp}>
      Consider offering horses with high energy requirements several small meals throughout the day.
    </p>
    <p style={tbodyp}>
      Starch in the diet should be limited if you are managing an overly excitable horse or a horse prone to conditions
      including gastric ulcers, tying up, Cushing’s disease, Equine Metabolic Syndrome.
    </p>
    <p style={tbodyp}>
      {calculateStarchEvaluation(props.contributors, props.horse)}
    </p>
  </div>
);

export const HorseReportStarchInfoSmall : React.FC<Props> = (props: Props) => (
  <p>
    <b>Starch:</b> Starch fuels anaerobic exercise which supports high intensity exercise such as jumping, galloping, and accelerating.
    {calculateStarchEvaluation(props.contributors, props.horse)}
    Feeding large amounts of
    starch in each meal can cause undigested starch to enter the hindgut.
  </p>
);

export default HorseReportStarchInfo;
