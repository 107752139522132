/* eslint-disable max-len */

import {
  EditableAccountOptions, EditableAccountOptionsActions, SET_EDITABLE_ACCOUNT_OPTION, SET_EDITABLE_ACCOUNT_OPTIONS,
} from './types';


const defaultState: EditableAccountOptions = {
  name: '',
  email: '',
  role: '',
  phoneNumber: '',
};


const editAccountReducer = (state: EditableAccountOptions = defaultState, action: EditableAccountOptionsActions) => {
  switch (action.type) {
  case (SET_EDITABLE_ACCOUNT_OPTION): {
    const newState = { ...state };
    const [key, value] = action.payload;
    newState[key as keyof EditableAccountOptions] = value;
    return { ...newState };
  }
  case (SET_EDITABLE_ACCOUNT_OPTIONS): {
    return { ...action.payload };
  }
  default: {
    return { ...state };
  }
  }
};

export default editAccountReducer;
