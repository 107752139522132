/* eslint-disable prefer-destructuring */
import { Feed, defaultNutrients, Nutrients } from './types';


// Contains the majority of the state that the database feed object requires.

const defaultNutrientsState = JSON.parse(JSON.stringify(defaultNutrients));

const defaultState: Feed = {
  id: '',
  name: '',
  nutrients: defaultNutrientsState,
  dm: 86,
  creationDate: new Date(),
  lastUpdated: new Date(),
  ownerId: '',
  ownerName: '',
  brandId: '',
  categoryId: '',
  ref: undefined as unknown as firebase.firestore.DocumentReference,
};

export const SET_ADD_FEED_VALUE = 'SET_ADD_FEED_VALUE';
export const SET_ADD_FEED_NUTRIENT = 'SET_ADD_FEED_NUTRIENT';
export const RESET_ADD_FEED_STATE = 'RESET_ADD_FEED_STATE';
export const TOGGLE_ADD_FEED_DM = 'TOGGLE_ADD_FEED_DM';

export interface setAddFeedValue {
  type: typeof SET_ADD_FEED_VALUE
  payload: [keyof Feed, any] // key, value
}

export interface setAddFeedNutrient {
  type: typeof SET_ADD_FEED_NUTRIENT
  payload: [keyof Nutrients, number] // key, value
}

export interface resetAddFeedState {
  type: typeof RESET_ADD_FEED_STATE
}

export interface toggleAddFeedDM {
  type: typeof TOGGLE_ADD_FEED_DM
  payload: boolean // toggle on to dm or toggle to per kg fed
}

type addFeedAction = setAddFeedNutrient | setAddFeedValue | resetAddFeedState | toggleAddFeedDM

const addFeedReducer = (state: Feed = defaultState, action: addFeedAction) => {
  switch (action.type) {
  case (SET_ADD_FEED_VALUE): {
    const newState: Feed = { ...state };
    (newState[action.payload[0]] as any) = action.payload[1];
    return { ...newState } as Feed;
  }
  case (SET_ADD_FEED_NUTRIENT): {
    const newNutrients: Nutrients = { ...state.nutrients };
    (newNutrients[action.payload[0]] as any).value = action.payload[1];
    return { ...state, nutrients: newNutrients } as Feed;
  }
  case (RESET_ADD_FEED_STATE): {
    defaultState.nutrients = JSON.parse(JSON.stringify(defaultNutrients));
    return defaultState;
  }
  case (TOGGLE_ADD_FEED_DM): {
    const nutrients = Object.assign(state.nutrients);
    if (action.payload) {
      Object.keys(nutrients).forEach((key) => nutrients[key as keyof Nutrients].value = (nutrients[key as keyof Nutrients].value) * (state.dm / 100));
    } else {
      Object.keys(nutrients).forEach((key) => nutrients[key as keyof Nutrients].value = ((nutrients[key as keyof Nutrients].value) / state.dm) * 100);
    }
    return { ...state, nutrients };
  }
  default: {
    return { ...state };
  }
  }
};

export default addFeedReducer;
