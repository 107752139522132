import React, { useState } from 'react';
import {
  makeStyles, Table, TableHead, Paper, TableRow, TableCell, Typography, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app';
import {
  Feed, addFeed, ADD_FEED, Nutrients,
} from '../redux/types';
import LoadingBackdrop from './LoadingBackdrop';
import { RootState } from '../redux/root';
import { Link as RouterLink } from 'react-router-dom';


const LOCALE: string = 'en-GB';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    flexGrow: 1,
  },
  table: {
    margin: 'auto',
  },
  paper: {
    flexGrow: 1,
    padding: '1em',
    margin: 'auto',
    display: 'flex',
    width: '90vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

interface Props {
  categoryId: string,
  brandId: string,
  feedId: string,
}

const ViewFeed: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const feeds: Map<string, Feed> = useSelector((state: RootState) => state.feeds.feeds);
  const [attemptedGet, setAttemptedGet] = useState(false);
  const dispatch = useDispatch();
  if (feeds.get(props.feedId) === undefined && !attemptedGet) {
    firebase.firestore().collection('feeds').doc(props.categoryId).collection('groups')
      .doc(props.brandId)
      .collection('feeds')
      .doc(props.feedId)
      .get()
      .then((feedDoc) => {
        if (!feedDoc.exists) return setAttemptedGet(true);
        const feedData: any = feedDoc.data();
        feedData.lastUpdated = feedData.lastUpdated.toDate();
        feedData.creationDate = feedData.creationDate.toDate();
        feedData.categoryId = props.categoryId;
        feedData.brandId = props.brandId;
        feedData.id = feedDoc.id;
        feedData.ref = feedDoc.ref;
        const newFeed: Feed = feedData as Feed;
        const action: addFeed = {
          type: ADD_FEED,
          payload: [feedDoc.id, newFeed],
        };
        dispatch(action);
      });
    return (
      <LoadingBackdrop />
    );
  }
  if (attemptedGet) {
    return <p>This feed appears to be lost in the manure...</p>;
  }
  const feed: Feed = feeds.get(props.feedId)!;
  return (
    <div className={classes.root}>
      <Typography style={{ marginLeft: '2em' }} variant="h4">{feed.name}</Typography>
      <Typography style={{ marginLeft: '4em' }}>
        Last updated on
        &nbsp;
        {feed.lastUpdated.toLocaleString(LOCALE)}
      </Typography>
      <Typography style={{ marginLeft: '4em' }}>
        Created on
        &nbsp;
        {feed.creationDate.toLocaleString(LOCALE)}
        &nbsp;
        by
        &nbsp;
        {feed.ownerName}
      </Typography>
      <Button component={RouterLink} to={window.location.pathname+'/edit'} variant="outlined" size="large" color="primary">Edit Feed</Button>
      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Nutrient
              </TableCell>
              <TableCell>
                Amount (as fed)
              </TableCell>
              <TableCell>
                Unit
              </TableCell>
            </TableRow>
            {Object.keys(feed.nutrients).map((key) => {
              const { nutrients } = feeds.get(props.feedId)!;
              const k = key as keyof Nutrients;
              return (
                <TableRow key={k}>
                  <TableCell>{nutrients[k].displayName}</TableCell>
                  <TableCell>{nutrients[k].value.toFixed(2)}</TableCell>
                  <TableCell>{nutrients[k].unit}</TableCell>
                </TableRow>
              );
            })}
          </TableHead>
        </Table>
      </Paper>
    </div>
  );
};

export default ViewFeed;
