import { Nutrients } from '../redux/types';

export enum nutrientRequirementState {
  RED = 0,
  YELLOW = 1,
  GREEN = 2,
}

export function EnergyHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `Digestible energy (DE) refers to the amount of energy in the diet that is
    available to the horse. The energy intake of the diet is within the recommended
    levels.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `Digestible energy (DE) refers to the amount of energy in the diet that is
    available to the horse. The energy intake of the diet is above the recommended
    level. Excess calorie intake will result in weight gain. DE is an estimated value so
    feed intake should always be adjusted according to the individual’s body
    condition.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    Digestible energy (DE) refers to the amount of energy in the diet that is
    available to the horse. The energy intake of the diet is below the recommended
    level. Insufficient calorie intake will result in weight loss. DE is an estimated value
    so feed intake should always be adjusted according to the individual’s body
    condition.`;
  }
  throw Error('Invalid nutrient state');
}

export function ProteinHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The protein content of the diet is within the recommended levels.
    Protein is used to build and repair body tissues and is a major component of
    muscles, skin, tendons, blood vessels, bone, hair and hooves. Protein also plays
    an important role in the immune, musculoskeletal, circulatory and reproductive systems.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The protein content of this diet is above the recommended level. Excess
    protein will be excreted in the urine. Water intake increases, which results in
    greater urine volume and there may be a noticeable ammonia smell in the
    stable.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The protein content of the diet is below the recommended level.
    Protein deficiency may result in reduced growth rates, poor muscle definition, a
    rough coat, reduced hoof growth, and abnormal skeletal development in young,
    growing horses. In lactating broodmares, milk production and quality will be
    reduced, resulting in impaired growth of the foal.`;
  }
  throw Error('Invalid nutrient state');
}

export function LysineHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The lysine content of the diet is within the recommended levels. Lysine is
    an essential amino acid, part of the building blocks that make up protein.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The lysine content of the diet is above the recommended level. Excess
    dietary lysine is associated with excess protein intake. Water intake increases,
    which results in greater urine volume and there may be a noticeable ammonia
    smell in the stable.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The lysine content of the diet is below the recommended level. Low
    dietary lysine intake will inhibit protein synthesis, required for healthy growth
    and development.`;
  }
  throw Error('Invalid nutrient state');
}

export function CalciumHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The calcium content of this diet is within the recommended levels.
    Calcium is an essential component of bone and provides strength to the
    skeleton. Calcium also plays a role in blood clotting and contributes to normal
    functioning of the cardiac, nervous and muscular system.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The calcium content of this diet is above the recommended level.
    Horses that are fed a diet high in calcium may be more prone to developing
    ‘thumps’ after intense endurance exercise. Excess dietary calcium can also have 
    a negative impact on the absorption of phosphorus.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The calcium content of this diet is below the recommended level.
    Calcium deficiency can result in abnormal bone development in young horses,
    softened or weakened bones in older horses, malfunction of the parathyroid
    gland and dental issues.`;
  }
  throw Error('Invalid nutrient state');
}


export function PhosphorusHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The phosphorous content of this diet is within the recommended
    levels. Phosphorous is an essential part of the skeleton and is required for
    energy transfer reactions.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The phosphorous content of this diet is above the recommended
    level. Excess phosphorous reduces the absorption of calcium which may result in
    skeletal abnormalities. Always consider the calcium to phosphorous ratio of the
    diet.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The phosphorous content of this diet is below the recommended
    level. Phosphorous deficiency can result in abnormal bone development in
    young horses, softened or weakened bones in older horses, malfunction of the
    parathyroid gland and dental issues.`;
  }
  throw Error('Invalid nutrient state');
}

export function SodiumHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The sodium content of this diet is within the recommended levels.
    Sodium is an essential electrolyte and plays a role in central nervous system
    function.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The sodium content of this diet is above the recommended level. If
    sufficient water is available, excess sodium will be excreted in the urine.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The sodium content of this diet is below the recommended level.
    Sodium deficiency may result in dehydration, decreased sweating, decreased
    performance, constipation, loss of appetite, uncoordinated muscle and chewing
    movement and general weakness.`;
  }
  throw Error('Invalid nutrient state');
}

export function ChlorideHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The chlorine content of this diet is within the recommended levels.
    Chlorine is an essential electrolyte and plays a role in the functioning of the
    central nervous system.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The chlorine content of this diet is above the recommended level. If
    sufficient water is available, excess sodium will be excreted in the urine.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The chlorine content of this diet is below the recommended level.
    Chlorine deficiency may cause dehydration, decreased feed intake, muscle
    weakness and decreased milk production.`;
  }
  throw Error('Invalid nutrient state');
}

export function PotassiumHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The potassium content of this diet is within the recommended levels.
    Potassium is an essential electrolyte and plays a role in neuromuscular function.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The potassium content of this diet is above the recommended level.
    Potassium is unlikely to cause toxicity and, providing adequate water is available,
    excess will be excreted in urine.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The potassium content of this diet is below the recommended level.
    Potassium deficiency results in decreased water intake and uncoordinated
    muscle movement.`;
  }
  throw Error('Invalid nutrient state');
}

export function MagnesiumHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The magnesium content of this diet is within the recommended
    levels. Magnesium is an essential electrolyte necessary for muscle and nerve
    function, energy metabolism and bone development.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The magnesium content of this diet is above the recommended
    level. Magnesium does not appear to be toxic to horses even at relatively high
    levels, excess will be excreted in the urine.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The magnesium content of this diet is below the recommended
    level. Magnesium deficiency can result in nervousness, muscle tremors and
    neurologic lameness in foals.`;
  }
  throw Error('Invalid nutrient state');
}

export function IronHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The iron content of this diet is within the recommended levels. Iron plays a
    critical role in oxygen transport and cellular respiration.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The iron content of this diet is above the recommended level. Excess iron
    may interfere with the absorption of other essential minerals.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The iron content of this diet is below the recommended level. Iron plays a
    critical role in oxygen transport and cellular respiration.`;
  }
  throw Error('Invalid nutrient state');
}

export function IodineHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The iodine content of this diet is within the recommended levels. Iodine
    is important for the functioning of the thyroid gland which plays an important
    role in heat production, growth, and metabolism.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The iodine content of this diet is above the recommended level. Excess
    iodine can cause enlargement of the thyroid gland (goitre), abortions and foal
    mortality.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The iodine content of this diet is below the recommended level. Diets
    low in iodine can cause enlargement of the thyroid gland (goitre), abortions and
    foal mortality.`;
  }
  throw Error('Invalid nutrient state');
}

export function CobaltHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The cobalt content of the diet is within the recommended levels. Cobalt
    is a naturally occurring mineral present in many forages and feed grains. Cobalt
    plays a role in protein synthesis, carbohydrate metabolism, fat metabolism and
    helps in red blood cell formation.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The cobalt content of the diet is above the recommended level. Further
    research is required on the implications of excess cobalt in the diet. High doses
    of cobalt have been suggested to increase the risk of abnormal heart function
    and sudden cardiac death.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The cobalt content of the diet is below the recommended level. Cobalt is
    a naturally occurring mineral present in many forages and feed grains, therefore
    a deficiency is rare and supplementary cobalt is usually not required.`;
  }
  throw Error('Invalid nutrient state');
}

export function CopperHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The copper content of this diet is within the recommended levels.
    Copper intake is important for the skin, coat condition, healthy bone formation,
    blood formation and elastic tissues.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The copper content of this diet is above the recommended level. Horses
    are relatively tolerant of high dietary copper; however excess copper 
    may interfere with the absorption of other essential minerals.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The copper content of this diet is below the recommended level.
    Feeding diets low in copper has been shown to increase the occurrence of
    osteochondritis dissecans, epiphysitis and limb deformities in young horses. Low
    copper diets will also have a negative impact on skin, coat condition, bone health
    and performance.`;
  }
  throw Error('Invalid nutrient state');
}

export function ManganeseHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The manganese content of this diet is within the recommended
    levels. Manganese is important for normal metabolism, the formation of healthy
    joints and the reproductive system.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The manganese content of this diet is above the recommended
    level. Manganese is among the least toxic of the trace elements, however,
    excess manganese in the ration can interfere with phosphorous absorption.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The manganese content of this diet is below the recommended
    levels. Manganese deficient diets can cause abnormal cartilage development
    which can result in bone malformation.`;
  }
  throw Error('Invalid nutrient state');
}

export function ZincHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The zinc content of this diet is within recommended levels. Zinc is involved
    in protein and carbohydrate synthesis and is important for the integrity of the
    skin and wound healing. The zinc content of the diet should be considered in
    conjunction with the copper intake.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The zinc content of this diet is above the recommended level. Horses
    appear to be quite tolerant of zinc, however, excess zinc may disrupt copper
    absorption and can lead to development of enlarged epiphyses, stiffness of gait
    and lameness.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The zinc content of this diet is below the recommended level. Zinc
    deficiency may cause reduced growth rates, impaired wound healing, and poor
    skin and coat condition. The zinc content of the diet should be considered in
    conjunction with the copper intake.`;
  }
  throw Error('Invalid nutrient state');
}

export function SeleniumHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The selenium content of this diet is within the recommended levels.
    Selenium is an important antioxidant. It is critical for heart health, skeletal
    muscle, immune function and sperm and colostrum quality.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The selenium content of this diet is above the recommended level.
    Selenium can be toxic at high levels. Speak to your nutritionist about adjusting
    selenium intake.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The selenium content of this diet is below the recommended level.
    Selenium is an important antioxidant. It is critical for heart health, skeletal
    muscle, immune function and sperm and colostrum quality.`;
  }
  throw Error('Invalid nutrient state');
}

export function VitAHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The vitamin A content of this diet is within the recommended levels.
    Vitamin A is important for healthy skin, mucous membranes, bone development,
    eyesight, immune function and reproduction.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The vitamin A content of this diet is above the recommended level.
    Vitamin A toxicity may result in skeletal abnormalities.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The vitamin A content of this diet is below the recommended level. A
    deficiency in vitamin A may cause impaired growth, reduced immune function
    and skeletal abnormalities. Night blindness is also a clinical sign of vitamin A
    deficiency.`;
  }
  throw Error('Invalid nutrient state');
}

export function VitDHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The vitamin D content of this diet is within the recommended levels.
    Vitamin D plays an important role in calcium absorption and is necessary for cell
    growth.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The vitamin D content of this diet is above the recommended level.
    Vitamin D toxicity is associated with calcification of soft tissue structures.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The vitamin D content of this diet is below the recommended level. A
    deficiency in vitamin D may hinder the absorption of calcium and phosphorus,
    resulting in impaired bone health. To date, there are no known reports of
    vitamin D deficiency in horses with adequate exposure to sunlight.`;
  }
  throw Error('Invalid nutrient state');
}

export function VitEHelper(nutrientState: nutrientRequirementState): string {
  if (nutrientState === nutrientRequirementState.GREEN) {
    return `The vitamin E content of this diet is within the recommended levels.
    Vitamin E is a key antioxidant and plays a role in immune response, nerve and muscle function.`;
  } if (nutrientState === nutrientRequirementState.YELLOW) {
    return `The vitamin E content of this diet is above the recommended level.
    Vitamin E does not appear to be toxic to horses even at relatively high intakes.`;
  } if (nutrientState === nutrientRequirementState.RED) {
    return `
    The vitamin E content of this diet is below the recommended level.
    Vitamin E deficiency can affect muscle function and immune response.`;
  }
  throw Error('Invalid nutrient state');
}

export function chartTextHelpers(nutrientName: keyof Nutrients) {
  switch (nutrientName) {
  case 'energy':
    return EnergyHelper;
  case 'crudeProtein':
    return ProteinHelper;
  case 'lysine':
    return LysineHelper;
  case 'calcium':
    return CalciumHelper;
  case 'phosphorous':
    return PhosphorusHelper;
  case 'sodium':
    return SodiumHelper;
  case 'chloride':
    return ChlorideHelper;
  case 'potassium':
    return PotassiumHelper;
  case 'magnesium':
    return MagnesiumHelper;
  case 'iron':
    return IronHelper;
  case 'iodine':
    return IodineHelper;
  case 'cobalt':
    return CobaltHelper;
  case 'copper':
    return CopperHelper;
  case 'manganese':
    return ManganeseHelper;
  case 'zinc':
    return ZincHelper;
  case 'selenium':
    return SeleniumHelper;
  case 'vitA':
    return VitAHelper;
  case 'vitD':
    return VitDHelper;
  case 'vitE':
    return VitEHelper;
  default: {
    return (_: nutrientRequirementState) => 'ERROR: No text helper available (chartTextHelpers.ts)';
  }
  }
}
