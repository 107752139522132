import React, { useState } from 'react';
import {
  makeStyles, Grid, Paper, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch } from 'react-redux';
import {
  Horse, NutritionReport, Feed, Nutrients, SpecialNutritionalBounds, User, SetCurrentNutritionReportValue, SET_CURRENT_NUTRITION_REPORT_VALUE,
} from '../redux/types';
import { NutrientRequirementsOutput } from '../helpers/nutrientRequirementWrapper';
import { BrandingID } from './dietPlan/ReportConstants';
import LoadingBackdrop from './LoadingBackdrop';
import Report from './dietPlan/Report';
import LongReport from './dietPlan/LongReport';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexGrow: 1,
  },
  paper: {
    flexGrow: 1,
    padding: '2em',
    margin: '4em',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      marginTop: '1em',
      width: '100%',
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

const saveShortReportAsPDF = (setHideLoading: Function) => {
  setHideLoading(false);
  const p1 = document.getElementById('page-1s') as HTMLElement;
  const p2 = document.getElementById('page-2s') as HTMLElement;
  const p3 = document.getElementById('page-3s') as HTMLElement;
  const p4 = document.getElementById('page-4s') as HTMLElement;
  const settings = { scale: 2.5, width: 695, height: 1042, windowWidth: 1920, windowHeight: 1080, scrollX: 0, scrollY: -window.scrollY };
  const promises = [
    html2canvas(p1, settings),
    html2canvas(p2, settings),
    html2canvas(p3, settings),
    html2canvas(p4, settings),
  ];
  Promise.all(promises).then(([c1, c2, c3, c4]) => {
    const i1 = c1.toDataURL('image/png');
    console.log(i1);
    c1.height = 0;
    c1.width = 0;
    const i2 = c2.toDataURL('image/png');
    c2.height = 0;
    c2.width = 0;
    const i3 = c3.toDataURL('image/png');
    c3.height = 0;
    c3.width = 0;
    const i4 = c4.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [695, 1042],
      compressPDF: true,
    });
    const leftMargin = 521.25 * 0.05;
    const topMargin = 781.5 * 0.025;
    const aiHeight = 781.5 * 0.95;
    const aiWidth = 521.25 * 0.9;
    pdf.addImage(i1, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i2, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i3, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i4, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    setHideLoading(true);
    pdf.save('EquineAppShortReport.pdf');
  });
};

const saveReportAsPDF = (setHideLoading: Function) => {
  setHideLoading(false);
  const p1 = document.getElementById('page-1') as HTMLElement;
  const p2 = document.getElementById('page-2') as HTMLElement;
  const p3 = document.getElementById('page-3') as HTMLElement;
  const p4 = document.getElementById('page-4') as HTMLElement;
  const p5 = document.getElementById('page-5') as HTMLElement;
  const p6 = document.getElementById('page-6') as HTMLElement;
  const p7 = document.getElementById('page-7') as HTMLElement;
  const p8 = document.getElementById('page-8') as HTMLElement;
  const settings = { scale: 2.5, width: 695, height: 1042, windowWidth: 1920, windowHeight: 1080, scrollX: 0, scrollY: -window.scrollY };
  const promises = [
    html2canvas(p1, settings),
    html2canvas(p2, settings),
    html2canvas(p3, settings),
    html2canvas(p4, settings),
    html2canvas(p5, settings),
    html2canvas(p6, settings),
    html2canvas(p7, settings),
    html2canvas(p8, settings),
  ];
  Promise.all(promises).then(([c1, c2, c3, c4, c5, c6, c7, c8]) => {
    const i1 = c1.toDataURL('image/png');
    c1.height = 0;
    c1.width = 0;
    const i2 = c2.toDataURL('image/png');
    c2.height = 0;
    c2.width = 0;
    const i3 = c3.toDataURL('image/png');
    c3.height = 0;
    c3.width = 0;
    const i4 = c4.toDataURL('image/png');
    c4.height = 0;
    c4.width = 0;
    const i5 = c5.toDataURL('image/png');
    c5.height = 0;
    c5.width = 0;
    const i6 = c6.toDataURL('image/png');
    c6.height = 0;
    c6.width = 0;
    const i7 = c7.toDataURL('image/png');
    c7.height = 0;
    c7.width = 0;
    const i8 = c8.toDataURL('image/png');
    c8.height = 0;
    c8.width = 0;
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [695, 1042],
      compressPDF: true,
    });
    const leftMargin = 521.25 * 0.05;
    const topMargin = 781.5 * 0.025;
    const aiHeight = 781.5 * 0.95;
    const aiWidth = 521.25 * 0.9;
    pdf.addImage(i1, 'PNG', leftMargin, 0, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i2, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i3, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i4, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i5, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i6, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i7, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    pdf.addPage();
    pdf.addImage(i8, 'PNG', leftMargin, topMargin, aiWidth, aiHeight, null, 'FAST');
    setHideLoading(true);
    pdf.save('EquineAppLongReport.pdf');
  });
};


const displayBrandPicker = (value: number, handleChange: Function) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">Branding</FormLabel>
    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={(e) => handleChange(Number(e.target.value))}>
      <FormControlLabel value={0} control={<Radio />} label="Redmills" />
      {/* <FormControlLabel value={1} control={<Radio />} label="Foran" /> */}
      <FormControlLabel value={2} control={<Radio />} label="Cobranding" />
    </RadioGroup>
  </FormControl>
);

const reportTypePicker = (value: number, handleChange: Function) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">Report Type</FormLabel>
    <RadioGroup aria-label="form type" name="formType" value={value} onChange={(e) => handleChange(Number(e.target.value))}>
      <FormControlLabel value={0} control={<Radio />} label="Long" />
      <FormControlLabel value={1} control={<Radio />} label="Short" />
    </RadioGroup>
  </FormControl>
);

interface Props {
  horse: Horse
  report: NutritionReport
  feeds: Map<string, Feed>
  combinedNetNutrients: Nutrients
  currentHorseRequirements: NutrientRequirementsOutput
  feedsAsDM: Nutrients
  contributors: [string, Nutrients][] | undefined
  percentages: Nutrients
  specialBounds: SpecialNutritionalBounds
  roughageAmount: number
  user: User
  totalFeedAmount: number
}


const HorseViewReport : React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [hideLoading, setHideLoading] = useState(true);
  const [reportType, setReportType] = useState(0);
  const [brandingID, setBrandingID]: [BrandingID, Function] = useState(0);
  const dispatch = useDispatch();
  const horseTitleChangeHandler = (newName: string) => {
    const action: SetCurrentNutritionReportValue = {
      type: SET_CURRENT_NUTRITION_REPORT_VALUE,
      payload: ['title', newName],
    };
    dispatch(action);
  };
  return (
    <div>
      <LoadingBackdrop hide={hideLoading} />
      <Paper style={{ display: hideLoading ? 'block' : 'none' }} className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              autoFocus
              id="name"
              label="Report title"
              type="text"
              fullWidth
              value={props.report.title}
              onChange={(e) => horseTitleChangeHandler(e.target.value)}
            />
          </Grid>
          <Grid item style={{ marginTop: '10px' }}>
            {displayBrandPicker(brandingID, setBrandingID)}
          </Grid>
          <Grid item style={{ marginTop: '10px' }}>
            {reportTypePicker(reportType, setReportType)}
          </Grid>
          <Grid item>
            <Button variant="outlined" style={{ marginBottom: '10px' }} color="secondary" onClick={() => (reportType === 0 ? saveReportAsPDF(setHideLoading) : saveShortReportAsPDF(setHideLoading))}>
              Save Report as PDF
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {reportType === 1
        ? (
          <Report brandingID={brandingID} {...props} />
        )
        : (
          <LongReport brandingID={brandingID} {...props} />
        )}
    </div>
  );
};

export default HorseViewReport;
