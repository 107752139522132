import React from 'react';
import {
  Horse, Nutrients, SpecialNutritionalBounds, Nutrient,
} from '../../redux/types';
import { NutrientRequirementsOutput } from '../../helpers/nutrientRequirementWrapper';
import { calculateSpecialRanges } from '../NutritionOverviewGraph';
import '../../styles/Tables.scss';

interface Props {
  combinedNetNutrients: Nutrients
  currentHorseRequirements: NutrientRequirementsOutput
  specialBounds: SpecialNutritionalBounds
}

const tableMappings = [
  ['energy', 'Energy_req'],
  ['crudeProtein', 'CrudeProtein_req'],
  ['lysine', 'Lysine_req'],
  ['calcium', 'Calcium_req'],
  ['phosphorous', 'Phosphorous_req'],
  ['sodium', 'Sodium_req'],
  ['chloride', 'Chloride_req'],
  ['potassium', 'Potassium_req'],
  ['magnesium', 'Magnesium_req'],
  ['iron', 'Iron_req'],
  ['iodine', 'Iodine_req'],
  ['cobalt', 'Cobalt_req'],
  ['copper', 'Copper_req'],
  ['manganese', 'Manganese_req'],
  ['zinc', 'Zinc_req'],
  ['selenium', 'Selenium_req'],
  ['vitA', 'VitaminA_req'],
  ['vitD', 'VitaminD_req'],
  ['vitE', 'VitaminE_req'],
];

const tdStyle = { padding: '2px', borderBottom: 'none', fontSize: '12px' };

const displayComment = (nutrientKey: keyof Nutrients, nutrientPercentage: Number, low: Nutrients, high: Nutrients) => {
  if (nutrientPercentage < low[nutrientKey].value) {
    return 'Low';
  } if (nutrientPercentage > high[nutrientKey].value) {
    return 'High';
  }
  return 'Good';
};

const displayTableBody = (combinedNetNutrients: Nutrients, currentHorseRequirements: NutrientRequirementsOutput, specialBounds: SpecialNutritionalBounds) => {
  const result: any = [];
  const [low, high] = calculateSpecialRanges(specialBounds);
  tableMappings.forEach(([nutriKey, reqKey]) => {
    const percentage = (Number(combinedNetNutrients[nutriKey as keyof Nutrients].value) / Number(currentHorseRequirements[reqKey as keyof NutrientRequirementsOutput])) * 100;
    result.push(
      <tr key={nutriKey}>
        <td style={tdStyle}>
          {`${combinedNetNutrients[nutriKey as keyof Nutrients].displayName} (${combinedNetNutrients[nutriKey as keyof Nutrients].unit})`}
        </td>
        <td style={tdStyle}>{currentHorseRequirements[reqKey as keyof NutrientRequirementsOutput].toFixed(2)}</td>
        <td style={tdStyle}>{combinedNetNutrients[nutriKey as keyof Nutrients].value.toFixed(2)}</td>
        <td style={tdStyle}>
          {(percentage).toFixed(0)}
          %
        </td>
        <td style={tdStyle}>{displayComment(nutriKey as keyof Nutrients, percentage, low, high)}</td>
      </tr>,
    );
  });
  return result;
};

const DietHorseTable: React.FC<Props> = (props: Props) => (
  <div style={{ fontSize: '15px' }}>
    <table
      style={{
        maxWidth: '500px',
        minWidth: '500px',
        margin: 'auto',
        marginTop: 0,
        padding: '20px',
      }}
      className="dietReportTable"
    >
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <td />
          <td>Daily Requirement</td>
          <td>Daily Intake</td>
          <td>Percentage</td>
          <td>Comment</td>
        </tr>
      </thead>
      <tbody>
        {displayTableBody(props.combinedNetNutrients, props.currentHorseRequirements, props.specialBounds)}
      </tbody>
    </table>
  </div>
);

export default DietHorseTable;
