import React from 'react';

import {
  Nutrients,
} from '../../redux/types';
import { table, theadStyle, tbodyp } from './horseReportStyle';


interface Props {
  contributors: Nutrients
  small?: boolean
}

const calculateSugarPercent = (netContributions: Nutrients) => {
  const otherMacrosSum = netContributions.crudeProtein.value + netContributions.fat.value + netContributions.starch.value + netContributions.sugar.value + netContributions.fibre.value;
  return Math.round((netContributions.sugar.value / otherMacrosSum) * (100 / 1));
};

const HorseReportSugarInfo : React.FC<Props> = (props: Props) => {
  const sugarPercent = calculateSugarPercent(props.contributors);
  return (
    <p style={props.small ? {} : tbodyp}>
      <b>Sugar:</b>This diet contains&nbsp;
      {sugarPercent}
      % sugar. Forage will often provide the bulk of the horse’s sugar intake. Grass hays typically
      range from 8 – 15% sugar per kilo dry matter, depending on the blend of grasses and the time the hay was cut. If
      your horse requires a lower sugar diet consider soaking your hay, choosing a later cut hay which typically contains a
      lower sugar content and/or limiting your horses grazing time. Total sugar intake should be limited to 10% or less for
      these horses.
    </p>
  );
};

export default HorseReportSugarInfo;
