/* eslint-disable camelcase */
import {
  Horse, HorseType, StallionHorse, PregnantHorse, LactatingHorse, MaintenanceHorse, ExerciseHorse, GrowingHorse,
} from '../redux/types';
import calculateNutrientRequirements from './NutrientRequirementsCalc';

export interface NutrientRequirementsOutput {
  Energy_req: number,
  CrudeProtein_req: number,
  Lysine_req: number,
  Calcium_req: number,
  Phosphorous_req: number,
  Magnesium_req: number,
  Sodium_req: number,
  Potassium_req: number,
  Chloride_req: number,
  Iron_req: number,
  Copper_req: number,
  Manganese_req: number,
  Zinc_req: number,
  Selenium_req: number,
  Iodine_req: number,
  VitaminA_req: number,
  VitaminD_req: number,
  VitaminE_req: number,
  Sulphur_req: number,
  Cobalt_req: number,
  Thiamine_req: number,
  Riboflavin_req: number,
}

export default function calculateRequirements(horse: Horse): NutrientRequirementsOutput {
  switch (horse.type) {
  case (HorseType.Stallion): {
    const d: any = calculateNutrientRequirements('STALLION', horse.weight, horse.age, horse.intakeLevel, 0, 0, 0, (horse as StallionHorse).special.stallionMaintenanceLevel, 0, 0);
    return d;
  }
  case (HorseType.Pregnant): {
    const d: any = calculateNutrientRequirements('PREGNANT', horse.weight, horse.age, horse.intakeLevel, (horse as PregnantHorse).special.gestationMonth, 0, 0, 0, 0, 0);
    return d;
  }
  case (HorseType.Lactating): {
    const d: any = calculateNutrientRequirements('LACTATING', horse.weight, horse.age, horse.intakeLevel, 0, (horse as LactatingHorse).special.lactationMonth, 0, 0, 0, 0);
    return d;
  }
  case (HorseType.Maintenace): {
    const d: any = calculateNutrientRequirements('MAINTENANCE', horse.weight, horse.age, horse.intakeLevel, 0, 0, (horse as MaintenanceHorse).special.maintenanceLevel, 0, 0, 0);
    return d;
  }
  case (HorseType.Exercise): {
    const d: any = calculateNutrientRequirements('EXERCISE', horse.weight, horse.age, horse.intakeLevel, 0, 0, 0, 0, 0, (horse as ExerciseHorse).special.workLoad);
    return d;
  }
  case (HorseType.Growing): {
    const d: any = calculateNutrientRequirements('GROWING', horse.weight, horse.age, horse.intakeLevel, 0, 0, 0, 0, (horse as GrowingHorse).special.growingWorkLoad, 0);
    return d;
  }
  default: {
    throw new Error('Invalid Horse Type!');
  }
  }
}
