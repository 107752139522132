import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper, Typography, Button, makeStyles, Grid, Card, CardContent, CardActionArea, CardActions,
} from '@material-ui/core';
import {
  Horse, AddHorsePropsFromhorse, ADD_HORSE_PROPS_FROM_HORSE, HorseType,
} from '../redux/types';
import DeleteHorseDialog from './DeleteHorseDialog';
import AddHorseDialog from './AddHorseDialog';
import { monthsToString } from '../helpers/HorseAgeHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '1em',
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexGrow: 1,
  },
  paper: {
    flexGrow: 1,
    padding: '2em',
    margin: '4em',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      marginTop: '1em',
      width: '100%',
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const extractHorseSpecialInformation = (horse: Horse) => {
  const maintenanceLevels = ['Low', 'Average', 'Heavy'];
  const stallionLevels = ['Breeding', 'Not Breeding'];
  const growingLevels = ['None', 'Low', 'Moderate', 'Heavy', 'Very Heavy'];
  const exerciseLevels = ['Low', 'Moderate', 'Heavy', 'Very Heavy'];
  switch (horse.type) {
  case HorseType.Maintenace:
    return ['Maintenance Level', maintenanceLevels[(horse.special as any).maintenanceLevel]];
  case HorseType.Stallion:
    return ['Stallion Maintenance Level', stallionLevels[(horse.special as any).stallionMaintenanceLevel]];
  case HorseType.Growing:
    return ['Growing Workload', growingLevels[(horse.special as any).growingWorkLoad]];
  case HorseType.Pregnant:
    return ['Month of Gestation', horse.special!.gestationMonth];
  case HorseType.Lactating:
    return ['Month of Lactation', horse.special!.lactationMonth];
  case HorseType.Exercise:
    return ['Exercise Workload', exerciseLevels[(horse.special as any).workload]];
  default:
    return ['ERROR', 'UNKNOWN'];
  }
};

const displayHorseInformation = (horse: Horse, setDeleteHorseDialogOpen: Function, setEditHorseDialogOpen: Function) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2" style={{ fontSize: '2rem' }}>
        Name -
        &nbsp;
        {horse.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: '1.2rem' }}>
        <Grid item>
          Comment -
          &nbsp;
          {horse.comment}
        </Grid>
        <Grid item>
          Breed -
          &nbsp;
          {horse.breed}
        </Grid>
        <Grid item>
          Age  -
          &nbsp;
          {monthsToString(horse.age)}
        </Grid>
        <Grid item>
          Type -
          &nbsp;
          {HorseType[horse.type]}
        </Grid>
        <Grid item>
          Mature Adult Bodyweight (kg) -
          &nbsp;
          {horse.weight}
        </Grid>
        <Grid item>
          Intake Level -
          &nbsp;
          {horse.intakeLevel}
        </Grid>
        <Grid item>
          {extractHorseSpecialInformation(horse)[0]}
          &nbsp;-&nbsp;
          {extractHorseSpecialInformation(horse)[1]}
        </Grid>
      </Typography>
    </CardContent>
    <CardActionArea>
      <CardActions>
        <Button size="large" color="primary" onClick={() => setEditHorseDialogOpen()}>Edit</Button>
        <Button size="large" color="secondary" onClick={() => setDeleteHorseDialogOpen(true)}>Delete Horse</Button>
      </CardActions>
    </CardActionArea>
  </Card>
);


interface Props {
  horse: Horse
  clientId: string
}

const HorseManageInformation : React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [deleteHorseDialogOpen, setDeleteHorseDialogOpen] = useState(false);
  const [editHorseDialogOpen, setEditHorseDialogOpen] = useState(false);
  const editHorseWrapper = () => {
    const action: AddHorsePropsFromhorse = {
      type: ADD_HORSE_PROPS_FROM_HORSE,
      payload: props.horse,
    };
    dispatch(action);
    setEditHorseDialogOpen(true);
  };
  return (
    <Paper className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <Typography
            variant="h3"
            align="center"
          >
            Manage Horse Information
          </Typography>
        </Grid>
        {displayHorseInformation(props.horse, setDeleteHorseDialogOpen, editHorseWrapper)}
      </Grid>
      <AddHorseDialog edit isOpen={editHorseDialogOpen} setOpen={setEditHorseDialogOpen} clientId={props.clientId} horseId={props.horse.id} />
      <DeleteHorseDialog isOpen={deleteHorseDialogOpen} setOpen={setDeleteHorseDialogOpen} clientId={props.clientId} horseId={props.horse.id} />
    </Paper>
  );
};

export default HorseManageInformation;
